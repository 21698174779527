import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import debounce from "lodash/debounce";
import { getActivePanel, useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { NextStepButton, useHandleNextStepClick, type NextStepButtonProps } from "@shared/features/GlobalHeader";
import { useGetDesignDocument } from "@six/features/DesignDocument";
import { useTriggerCanvasChange } from "@six/features/DesignPanel";
import { usePattern } from "@six/features/Patterns/usePattern";
import { useCheckValidationsForNextStepStudio6 } from "./useCheckValidationsForNextStep";

export const NextStepButtonStudio6 = observer((props: Omit<NextStepButtonProps, "onClick">) => {
    const buttonRef = useRef(null);
    const triggerCanvasChange = useTriggerCanvasChange();
    const getDocument = useGetDesignDocument({ removeUnreplacedPlaceholders: true, action: "NextStep" });
    const designEngine = useAvailableDesignEngine();
    const checkValidations = useCheckValidationsForNextStepStudio6();
    const patternItem = usePattern();

    const handleNextStepClick = useHandleNextStepClick({
        checkValidations,
        activeCanvasName: designEngine ? getActivePanel(designEngine)?.panelProperties.name : undefined,
        triggerCanvasChange,
        getDocument,
        patternItem
    });
    const debouncedHandleNextClick = debounce(handleNextStepClick, 250);

    return <NextStepButton {...props} ref={buttonRef} onClick={debouncedHandleNextClick} disabled={props.disabled} />;
});
