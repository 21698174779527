import React from "react";
import { Typography, ToggleSwitch, FlexBox, ToggleSwitchOnLabel, ToggleSwitchOffLabel } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@internal/feature-responsive-design";
import classnames from "classnames";
import { useShowPanelSections } from "../PanelSections";
import * as styles from "./ViewToggleRow.module.scss";

const messages = defineMessages({
    on: {
        id: "studio.ui.component.guidesPanelContent.on",
        defaultMessage: "On",
        description: {
            note: "Toggle switched On"
        }
    },
    off: {
        id: "studio.ui.component.guidesPanelContent.off",
        defaultMessage: "Off",
        description: {
            note: "Toggle switched Off"
        }
    }
});

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    labelId: string;
    activated: boolean;
    onRequestActivatedChange?: (activated: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    description: string;
    icon?: JSX.Element;
}

export function ViewToggleRow(props: Props) {
    const { label, labelId, activated, onRequestActivatedChange, description, className, icon, ...rest } = props;
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const labelSize = isSmall ? "small" : "standard";
    const descriptionSize = isSmall ? "xsmall" : "small";
    const toggleStyle = isSmall ? "mini" : "standard";
    const descriptionId = `${labelId}-description`;
    const shouldShowPanelSections = useShowPanelSections();

    return (
        <FlexBox marginTop={"5"} alignItems="center">
            {shouldShowPanelSections && <div className={classnames(className, styles.guidesIcon)}>{icon}</div>}
            <FlexBox flexDirection="column">
                <FlexBox
                    alignItems="center"
                    marginX={0}
                    marginBottom={0}
                    className={classnames(className, styles.guideToggleRow)}
                    {...rest}
                >
                    <Typography id={labelId} marginRight="auto" fontSize={labelSize}>
                        {label}
                    </Typography>
                    <ToggleSwitch
                        size={toggleStyle}
                        hideText
                        activated={activated}
                        onRequestActivatedChange={onRequestActivatedChange}
                        aria-labelledby={labelId}
                        aria-describedby={descriptionId}
                        data-testid={`view-panel-toggle-${labelId}`}
                    >
                        <ToggleSwitchOnLabel>{t(messages.on.id)}</ToggleSwitchOnLabel>
                        <ToggleSwitchOffLabel>{t(messages.off.id)}</ToggleSwitchOffLabel>
                    </ToggleSwitch>
                </FlexBox>
                <Typography
                    id={descriptionId}
                    textColor="subtle"
                    fontSize={descriptionSize}
                    className={classnames(styles.toggleDescriptionStyle, {
                        [styles.toggleTextStyle]: shouldShowPanelSections
                    })}
                >
                    {description}
                </Typography>
            </FlexBox>
        </FlexBox>
    );
}
ViewToggleRow.displayName = "ViewToggleRow";
