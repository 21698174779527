import { useCallback, useState } from "react";
import { retry } from "@internal/utils-network";
import { type GetDocument } from "@internal/utils-cimdoc";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { getTemporaryPreviewInstructionsUrl } from "@shared/utils/Previews";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { DownloadConfig } from "@six/experiments/digital/DownloadFlow/types";
import { buildRenderingUrl } from "../renderingClient";

interface Props {
    getDocument: GetDocument;
}

const triggerProgrammaticDownload = async (url: string, filename: string, abortSignal?: AbortSignal) => {
    const [fileSaver, response] = await Promise.all([
        retry(() => import("file-saver"), { name: "import" }),
        retry(() => fetch(url, { signal: abortSignal }), { name: filename })
    ]);
    const blob = await response.blob();

    const {
        default: { saveAs }
    } = fileSaver;

    saveAs(blob, filename);
};

const useGeneratePreview = ({ config, getDocument }: Props & { config: DownloadConfig }) => {
    const { auth } = useIdentityContext();
    const [error, setError] = useState<Error | null>(null);
    const authToken = auth?.getToken();

    const downloadPreview = useCallback(
        // eslint-disable-next-line consistent-return
        async (abortSignal: AbortSignal) => {
            const generateStart = performance.now();
            setError(null);
            try {
                fireDesignToolTrackingEvent({
                    eventDetail: "Generate Digital Download Button Clicked",
                    label: "Generate Digital Download Button",
                    pageNameBase: undefined,
                    extraData: undefined
                });

                const designDocument = await getDocument();
                const previewInstructionsUri = await getTemporaryPreviewInstructionsUrl({
                    authToken,
                    designDocument
                });

                const renderingUrl = buildRenderingUrl({
                    instructionsUri: previewInstructionsUri,
                    ...config
                });

                await triggerProgrammaticDownload(renderingUrl, config.filename, abortSignal);
                const generateEnd = performance.now();
                const timeElapsed = generateEnd - generateStart;

                fireDesignToolTrackingEvent({
                    eventDetail: "Generate Preview Download Processed",
                    label: "Generate Digital Download Button",
                    pageNameBase: undefined,
                    extraData: () => ({ timeToProcess: timeElapsed })
                });
            } catch (error) {
                if (error.abort) {
                    return error;
                }

                if (!error.message) {
                    error.message = new Error("Unknown Exception from Prepress Client");
                }
                setError(error);

                const generateEnd = performance.now();

                fireDesignToolTrackingEvent({
                    eventDetail: "Generate Preview Download Failed",
                    label: "Generate Digital Download Button",
                    pageNameBase: undefined,
                    extraData: () => ({ timeToProcess: generateEnd - generateStart })
                });
            }
        },
        [config, authToken, getDocument]
    );

    return { downloadPreview, error };
};

export { useGeneratePreview };
