/**
Provider KeyboardShortcutsProvider
* */
import React, { ReactNode, useContext, useMemo, useState } from "react";
import {
    HotKeysToEventMap,
    keyboardShortcutEvents,
    keyboardShortcutsConfig as defaultKeyboardShortcutsConfig
} from "./keyboardShortcutEvents";

interface KeyboardShortcutsContext {
    keyboardShortcutsConfig?: { mac?: HotKeysToEventMap; windows?: HotKeysToEventMap }; // configure hot keys and events that you want to enable shortcut for.
    allowedEvents: { macEventsSet: Set<string>; windowsEventsSet: Set<string> }; // set of events based on config that are allowed.
    isKeyboardShortcutsGuideModalOpen: boolean;
    setIsKeyboardShortcutsGuideModalOpen: (value: boolean) => void;
    selectedOS: string;
    setSelectedOS: (value: string) => void;
}
interface shortcutProviderProps {
    children: ReactNode | ReactNode[];
    keyboardShortcutsConfig?: {
        mac?: { [key: string]: keyboardShortcutEvents };
        windows?: { [key: string]: keyboardShortcutEvents };
    };
}

const initContext = {
    registeredFunctions: {},
    setRegisteredFunctions: () => null,
    keyboardShortcutsConfig: { mac: {}, windows: {} },
    allowedEvents: { macEventsSet: new Set<string>(), windowsEventsSet: new Set<string>() },
    isKeyboardShortcutsGuideModalOpen: false,
    setIsKeyboardShortcutsGuideModalOpen: (value: boolean) => null,
    selectedOS: "",
    setSelectedOS: (value: string) => null
};
const context = React.createContext<KeyboardShortcutsContext | undefined>(initContext);

export const useKeyboardShortcutContext = () => {
    const result = useContext(context);
    if (!result) {
        throw Error("Please call this within KeyboardShortcutContext");
    }
    return result;
};

export const KeyboardShortcutsProvider = ({
    children,
    keyboardShortcutsConfig = defaultKeyboardShortcutsConfig
}: shortcutProviderProps) => {
    const { Provider } = context;
    const [isKeyboardShortcutsGuideModalOpen, setIsKeyboardShortcutsGuideModalOpen] = useState(false);
    const [selectedOS, setSelectedOS] = useState("");

    const allowedEvents = useMemo(() => {
        const macEventsSet = new Set<string>(Object.values(keyboardShortcutsConfig?.mac || {}));
        const windowsEventsSet = new Set<string>(Object.values(keyboardShortcutsConfig?.windows || {}));
        return {
            macEventsSet,
            windowsEventsSet
        };
    }, [keyboardShortcutsConfig]);

    const contextValue = useMemo(() => {
        return {
            keyboardShortcutsConfig,
            allowedEvents,
            isKeyboardShortcutsGuideModalOpen,
            setIsKeyboardShortcutsGuideModalOpen,
            selectedOS,
            setSelectedOS
        };
    }, [
        keyboardShortcutsConfig,
        allowedEvents,
        isKeyboardShortcutsGuideModalOpen,
        setIsKeyboardShortcutsGuideModalOpen,
        selectedOS,
        setSelectedOS
    ]);

    return <Provider value={contextValue}>{children}</Provider>;
};

KeyboardShortcutsProvider.displayName = "KeyboardShortcutsProvider";
