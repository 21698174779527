import { css, cx } from "@emotion/css";
import type { ReactNode } from "react";
import React from "react";
import { DesignLayer } from "@design-stack-vista/ida-framework";

export interface SimpleSceneLayerProps {
    /** The child element(s) */
    children?: ReactNode | ReactNode[];
    /**
     * Optional background css value
     * @defaultValue `white`
     */
    background?: string;
    /**
     * Optional outline css value
     * @defaultValue `1px solid #C4CDD6`
     */
    outline?: string;
    /**
     * Optional CSS class name to add custom style
     */
    className?: string;
}

export function SimpleSceneLayer({
    children,
    background = "white",
    outline = "1px solid #C4CDD6",
    className
}: SimpleSceneLayerProps) {
    return (
        <DesignLayer name="simple-scene">
            <div
                className={cx(
                    "dec-ida-simple-scene-layer",
                    css`
                        width: 100%;
                        height: 100%;
                        background: ${background};
                        outline: ${outline};
                    `,
                    className
                )}
            >
                {children}
            </div>
        </DesignLayer>
    );
}
