import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { UndoIcon } from "@internal/ui-studio-chrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { Typography } from "@vp/swan";

const messages = defineMessages({
    undo: {
        id: "studio.components.sidebar.undo.label",
        defaultMessage: "Undo",
        description: {
            note: "Label for the sidebar undo button"
        }
    }
});

type Props = {
    className?: string;
    showLabel?: boolean;
    disabled?: boolean;
    onClick: () => void;
};

export const UndoButton = (props: Props) => {
    const { onClick, showLabel, className, disabled, ...rest } = props;
    const { t } = useTranslationSSR();

    const handleOnClick = () => {
        onClick();
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_UNDO,
            label: "Click undo"
        });
    };

    return (
        <button
            onClick={handleOnClick}
            disabled={disabled}
            className={classNames("undo-button", className)}
            aria-label={!showLabel ? t(messages.undo.id) : undefined}
            data-testid="undo-button"
            {...rest}
        >
            <UndoIcon aria-hidden={true} />
            {showLabel && (
                <Typography fontWeight="bold" fontSize="xsmall">
                    {t(messages.undo.id)}
                </Typography>
            )}
        </button>
    );
};

UndoButton.displayName = "UndoButton";
