import { Button } from "@vp/swan";
import React, { ComponentProps } from "react";

export type DownloadAnchorButtonProps = {
    className?: string;
    label: string;
    previewUrl?: string;
} & ComponentProps<typeof Button>;

export const DownloadAnchorButton = (props: DownloadAnchorButtonProps) => {
    const {
        buttonShape,
        children,
        className,
        label,
        previewUrl,
        size = "standard",
        skin = "primary",
        onClick,
        ...rest
    } = props;

    return (
        <Button
            {...rest}
            // @ts-expect-error download is a valid html5 anchor attribute
            download
            aria-label={label}
            as="a"
            buttonShape={children ? buttonShape : "round"}
            className={className}
            disabled={!previewUrl && !onClick}
            href={previewUrl}
            iconPosition="left"
            skin={skin}
            size={size}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};
