import React, { useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button, Typography } from "@vp/swan";
import { FolderIcon } from "@internal/ui-studio-chrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setTrackModalTransitionEvent, setShowMyProjects } from "@shared/redux";
// eslint-disable-next-line import/no-restricted-paths -- dependencies must be extracted but beyond current effort
import { useGetDocumentForSave } from "@six/features/Save/useGetDocumentForSave";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { messages } from "./messages";
import { useSave } from "../Save";

interface Props {
    className?: string;
    hideIcon?: boolean;
    enableModalTransitionEvent?: boolean;
    onPanelOpen?: () => void;
}

export const MyProjectsButton = observer(
    ({ className, hideIcon, enableModalTransitionEvent = false, onPanelOpen }: Props) => {
        const { t } = useTranslationSSR();
        const dispatch = useAppDispatch();
        const loginReason = useAppSelector(state => state.loginReason);
        const showMyProjects = useAppSelector(state => state.showMyProjects.show);
        const trackModalTransitionEvent = useAppSelector(state => state.trackModalTransitionEvent);
        const startTimeRef = useRef<number>(0);
        const setStartTime = (startTime: number) => {
            startTimeRef.current = startTime;
        };

        const save = useSave({
            onSaveCallback: useCallback(() => {
                dispatch(setShowMyProjects({ show: true, startTime: startTimeRef.current }));
                dispatch(
                    setTrackModalTransitionEvent({
                        ...trackModalTransitionEvent,
                        isEventActive: enableModalTransitionEvent
                    })
                );
            }, [dispatch, enableModalTransitionEvent, trackModalTransitionEvent]),
            showSaveToast: false,
            allowAnonymousUser: true,
            trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_MY_PROJECTS,
            getDocumentForSave: useGetDocumentForSave(),
            documentMetadata: useDocumentMetadataForSave()
        });

        useEffect(() => {
            if (loginReason === "myProjectsPanel" && !showMyProjects) {
                dispatch(setShowMyProjects({ show: true }));
                dispatch(
                    setTrackModalTransitionEvent({
                        ...trackModalTransitionEvent,
                        isEventActive: enableModalTransitionEvent
                    })
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [loginReason]);

        const handleOpen = () => {
            onPanelOpen && onPanelOpen();
            dispatch(
                setTrackModalTransitionEvent({
                    ...trackModalTransitionEvent,
                    isEventActive: enableModalTransitionEvent
                })
            );
            const startTime = performance.now();
            setStartTime(startTime);
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MY_PROJECTS,
                label: "My Projects Button"
            });
            save();
        };

        return (
            <Button skin="unstyled" className={className} onClick={handleOpen}>
                {!hideIcon ? <FolderIcon aria-hidden={true} /> : null}
                <Typography>{t(messages.myProjectsLink.id)}</Typography>
            </Button>
        );
    }
);

MyProjectsButton.displayName = "MyProjectsButton";
