import { useCallback } from "react";
import { useAppSelector } from "@shared/redux";
import { useCanvasValidations } from "@shared/features/Validations";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { PreviewUrl } from "@shared/features/Previews";
import { CUSTOM_SELECTED_TEMPLATE } from "@internal/data-access-template";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useStudioConfigurationManager } from "@internal/dex";
import { useSelectCanvasOnChoice } from "./useSelectCanvasOnChoice";
import { DesignPanelType, useDesignDialog } from "../DesignDialogProvider";
import { useGetPanelSourceExtension } from "./useGetPanelSourceExtension";

interface Props {
    activeCanvasName: string | undefined;
    triggerCanvasChange: TriggerCanvasChange;
    fromNextStep?: boolean;
}

export function useOnCanvasClick({ activeCanvasName, triggerCanvasChange, fromNextStep }: Props) {
    const onSelectCanvas = useSelectCanvasOnChoice();
    const { validateCanvasErrors } = useCanvasValidations();
    const { setDesignPanelType, setDialogCanvas, getHasDialogBeenSeen } = useDesignDialog();
    const designDialogsSeen = useAppSelector(state => state.designDialogsSeen);
    const matchingTemplates = useAppSelector(state => state.matchingTemplates);
    const { isFullBleed } = useProductAndProjectStateManager().data;
    const getPanelSourceExtension = useGetPanelSourceExtension();
    const { showDesignPanel } = useStudioConfigurationManager().data;

    return useCallback(
        async (canvasData: PreviewUrl, onSelection?: () => void) => {
            const newCanvasName = canvasData.name;
            if (activeCanvasName === newCanvasName) {
                return;
            }

            if (validateCanvasErrors()) {
                triggerCanvasChange(newCanvasName);
                const canvasOption = onSelectCanvas(newCanvasName);
                const panelSourceExtension = getPanelSourceExtension(newCanvasName);

                const safeCanvasOption = canvasOption === undefined || canvasOption === CUSTOM_SELECTED_TEMPLATE;
                const isFullBleedWithNoTemplates = isFullBleed && !matchingTemplates?.length;

                const hasItems = panelSourceExtension?.hasItems;
                if (
                    panelSourceExtension &&
                    // dont show this for the first canvas even if happened to start on a different canvas
                    panelSourceExtension.index > 0 &&
                    panelSourceExtension.colorMode !== "blank" &&
                    // don't show for full bleed if there are no matching templates
                    !isFullBleedWithNoTemplates &&
                    // don't show if the dialog has been seen in the current studio session
                    !getHasDialogBeenSeen(newCanvasName) &&
                    // if showDesignPanel is disabled, we don't show the design panel at all and skip this
                    showDesignPanel
                ) {
                    setDialogCanvas(canvasData);

                    if (fromNextStep && safeCanvasOption && !hasItems) {
                        setDesignPanelType(DesignPanelType.ChangeAutoShowFromNext);
                        // don't show the dialog if it has been seen, in this session or a previous session
                    } else if (!designDialogsSeen[newCanvasName]) {
                        setDesignPanelType(DesignPanelType.ChangeAutoShow);
                    }
                }

                onSelection?.();
            }
        },
        [
            activeCanvasName,
            designDialogsSeen,
            fromNextStep,
            getHasDialogBeenSeen,
            getPanelSourceExtension,
            isFullBleed,
            matchingTemplates?.length,
            onSelectCanvas,
            setDesignPanelType,
            setDialogCanvas,
            triggerCanvasChange,
            validateCanvasErrors,
            showDesignPanel
        ]
    );
}
