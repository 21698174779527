import { useCartContext } from "@internal/utils-cart";
import { GetDocument } from "@internal/utils-cimdoc";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { DesignPanelType, useDesignDialog, useOnCanvasClick } from "@shared/features/DesignPanel";
import { usePreviewsContext } from "@shared/features/Previews";
import {
    useDesignRequirementsContext,
    useIsBlankAndHasNoUpsells,
    useNeedAddButtonContext
} from "@shared/features/Product";
import { useNavigateToTeamsPage } from "@shared/features/Teams";
import {
    setShowBlankDocumentAlert,
    setShowFullBleedPremiumFinishErrorModal,
    showDesignReview,
    useAppDispatch
} from "@shared/redux";
import { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { fireReviewPageTrackingEvent } from "@shared/utils/Tracking";
import { useCallback } from "react";

import { Item } from "@design-stack-vista/cdif-types";
import { ItemState, SubpanelState } from "@design-stack-vista/cimdoc-state-manager";
import { useStudioConfigurationManager } from "@internal/dex";
import { Events, useTrackingClient } from "@internal/utils-tracking";
// @note Sim import violation with be addressed in https://vistaprint.atlassian.net/browse/DJ-451
// eslint-disable-next-line no-restricted-imports
import { useHasTeamsNameItems } from "@internal/advanced-editor-sim-teams-name";
import { useDeletePanel } from "@shared/features/DesignPanel/hooks/useDeletePanel";
import { getPanelIndex } from "@shared/features/DesignPanel/utils";
import { useDesignReview } from "@shared/features/DesignReview";
// eslint-disable-next-line import/no-restricted-paths
import { usePremiumFinishReviewInstructionsRequired } from "@six/features/editorUI/premiumFinish/usePremiumFinishReviewInstructionsRequired";
import { BlankDocumentValidationPanel, useBlankDocumentValidation } from "./useBlankDocumentValidation";

type NextStepActionType = "addDialog" | "selectCanvas" | "reviewPage" | "teamDetailsPage" | "blankDocument";

type NextStepAction = {
    action: NextStepActionType;
    canvasName: string;
    canvasIndex: number;
};

interface Props {
    activeCanvasName: string | undefined;
    getDocument: GetDocument;
    triggerCanvasChange: TriggerCanvasChange;
    patternItem: (ItemState<Item> | SubpanelState)[] | undefined;
    checkValidations: () => boolean;
}

export function useHandleNextStepClick({
    activeCanvasName,
    getDocument,
    triggerCanvasChange,
    patternItem,
    checkValidations
}: Props) {
    const { isBlankDocumentValidationEnabled, shouldShowPatterns } = useStudioConfigurationManager().data;
    const { designPanelType, dialogCanvasName, getHasDialogBeenSeen } = useDesignDialog();
    const isBlankAndHasNoUpsells = useIsBlankAndHasNoUpsells();
    const needAddButton = useNeedAddButtonContext();
    const { isItemInCart } = useCartContext();
    const trackingClient = useTrackingClient();
    const hasTeamsNameItems = useHasTeamsNameItems();

    const dispatch = useAppDispatch();

    const premiumFinishReviewInstructionsRequired = usePremiumFinishReviewInstructionsRequired(activeCanvasName);
    const { setDesignPanelType, setDialogCanvas } = useDesignDialog();
    const { canvasSelectorUrls } = usePreviewsContext();
    const onCanvasClick = useOnCanvasClick({
        activeCanvasName,
        triggerCanvasChange,
        fromNextStep: true
    });
    const designRequirements = useDesignRequirementsContext();
    const navigateToTeamsPage = useNavigateToTeamsPage();
    const deletePanel = useDeletePanel();
    const { setIsBlankBackSideRemoved } = useDesignReview();

    const { isDocumentBlank, isPanelBlank } = useBlankDocumentValidation();

    /**
     * Checks if the document has an empty back panel and performs the necessary actions if it does.
     *
     * This function is used to validate the state of the document when the "Next Step" button is clicked.
     * If the document has an empty back panel and the `isBlankDocumentValidationEnabled` flag is enabled,
     * this function will call the `removeEmptyBackPanel` callback to handle the empty back panel case.
     */
    const handleBlankBackPanel = useCallback(async () => {
        if (isBlankDocumentValidationEnabled) {
            const cimDoc = await getDocument();
            const isBackPanelBlank = await isPanelBlank(cimDoc, BlankDocumentValidationPanel.Back);
            /**
             * We are disabling blankDocument validation if Groups and team items are added to the document.
             * As G&T has a different `teamDetailsPage` page that is outside of Studio, we are excluding
             * blank backside document validation.
             */
            if (isBackPanelBlank && !hasTeamsNameItems()) {
                const backIndex = getPanelIndex(cimDoc, BlankDocumentValidationPanel.Back);
                if (backIndex !== -1 && designRequirements !== undefined) {
                    const panelName = cimDoc.document.panels[backIndex].name;
                    const defaultPanelName = designRequirements.getDefaultPanelName();

                    deletePanel(panelName);
                    triggerCanvasChange(defaultPanelName);
                    // Enable the warning in design review after deletion of the back panel.
                    setIsBlankBackSideRemoved(true);
                }
            }
        }
    }, [
        isPanelBlank,
        deletePanel,
        designRequirements,
        getDocument,
        hasTeamsNameItems,
        isBlankDocumentValidationEnabled,
        setIsBlankBackSideRemoved,
        triggerCanvasChange
    ]);

    const handleNextStepClick = useCallback(async () => {
        /**
         * Take a look at the canvas and find what the next action should be
         */
        const getNextStepAction = async (): Promise<NextStepAction> => {
            // start with the current canvasOrdinal.
            if (designRequirements && activeCanvasName) {
                let canvasIndex = designRequirements.getPanelIndexByName(activeCanvasName);
                if (shouldShowPatterns && patternItem?.length) {
                    trackingClient.track(Events.DesignToolUsed, {
                        eventDetail: "Design completed with Pattern",
                        label: "Design completed with Pattern"
                    });
                }

                // check if the document is blank or not; only for enabled PPAG products
                if (isBlankDocumentValidationEnabled) {
                    const cimDoc = await getDocument();
                    const documentIsBlank = await isDocumentBlank(cimDoc);

                    if (documentIsBlank) {
                        return { canvasName: "", canvasIndex, action: "blankDocument" };
                    }
                }

                // always go directly to design review for items in cart, excluding items with teams experience enabled
                if (isItemInCart && !hasTeamsNameItems()) {
                    return { canvasName: "", canvasIndex, action: "reviewPage" };
                }
                if (designPanelType === DesignPanelType.CheckoutAdd && designRequirements) {
                    canvasIndex = designRequirements.getPanelIndexByName(dialogCanvasName);
                }

                // We want to start on the next one so we add one to it.
                canvasIndex += 1;

                // Subtract 1 here to make it 0 based
                while (canvasIndex < designRequirements.numberOfPanels) {
                    // Get the specific canvas this is 0 based, so we need to take one off the index.
                    const panel = designRequirements.panels[canvasIndex];
                    // don't navigate to non-designable canvases
                    if (!isBlankAndHasNoUpsells(panel, canvasIndex)) {
                        // Does the canvas show the add button?
                        if (needAddButton(panel, panel.name)) {
                            // If so, have we already seen the dialog?
                            if (!getHasDialogBeenSeen(panel.name)) {
                                // If not create the action for us to do
                                return { canvasName: panel.name, canvasIndex, action: "addDialog" };
                            }
                            // Nope... move along
                        } else {
                            // We want to select the canvas
                            return { canvasIndex, canvasName: panel.name, action: "selectCanvas" };
                        }
                    }
                    // Go to the next canvas
                    canvasIndex += 1;
                }
            }
            // Go to the Team Details Page
            if (hasTeamsNameItems()) {
                return { canvasName: "", canvasIndex: 0, action: "teamDetailsPage" };
            }
            // Go to the review page
            return { canvasName: "", canvasIndex: 0, action: "reviewPage" };
        };

        if (premiumFinishReviewInstructionsRequired) {
            dispatch(setShowFullBleedPremiumFinishErrorModal(true));
        } else if (checkValidations()) {
            const { canvasIndex, action } = await getNextStepAction();
            switch (action) {
                case "blankDocument":
                    dispatch(setShowBlankDocumentAlert(true));
                    break;
                case "addDialog":
                    setDesignPanelType(DesignPanelType.CheckoutAdd);
                    setDialogCanvas(canvasSelectorUrls[canvasIndex]);
                    break;
                case "selectCanvas":
                    setDesignPanelType(DesignPanelType.None);
                    setDialogCanvas(undefined);
                    onCanvasClick(canvasSelectorUrls[canvasIndex]);
                    break;
                case "teamDetailsPage":
                    navigateToTeamsPage();
                    break;
                case "reviewPage":
                default: {
                    handleBlankBackPanel();
                    const startTime = performance.now();
                    setDesignPanelType(DesignPanelType.None);
                    setDialogCanvas(undefined);
                    window.history.pushState(null, "Title");
                    dispatch(showDesignReview({ show: true, startTime }));
                    fireReviewPageTrackingEvent({
                        extraData: () => ({
                            editFromCartFlow: isItemInCart
                        })
                    });
                    newRelicWrapper.addPageAction("studio-review");
                    break;
                }
            }
        }
    }, [
        premiumFinishReviewInstructionsRequired,
        checkValidations,
        designRequirements,
        activeCanvasName,
        hasTeamsNameItems,
        shouldShowPatterns,
        patternItem?.length,
        isBlankDocumentValidationEnabled,
        isItemInCart,
        designPanelType,
        trackingClient,
        getDocument,
        isDocumentBlank,
        dialogCanvasName,
        isBlankAndHasNoUpsells,
        needAddButton,
        getHasDialogBeenSeen,
        dispatch,
        setDesignPanelType,
        setDialogCanvas,
        canvasSelectorUrls,
        onCanvasClick,
        navigateToTeamsPage,
        handleBlankBackPanel
    ]);

    return handleNextStepClick;
}
