import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { FlexBox, Link, Typography } from "@vp/swan";
import { KeyboardIcon } from "@internal/ui-studio-chrome";
import { useKeyboardShortcutContext } from "./KeyboardShortcutsContext";
import * as styles from "./KeyboardShortcutsButton.module.scss";

const messages = defineMessages({
    keyboardShortcutsLinkButton: {
        id: "studio.components.keyboardShorcuts.keyboardShortcutsLinkButton",
        defaultMessage: "See keyboard shortcuts",
        description: {
            note: "text for view modal keyboard shortcuts button"
        }
    }
});

export function KeyboardShortcutsButton() {
    const { t } = useTranslationSSR();
    const { setIsKeyboardShortcutsGuideModalOpen } = useKeyboardShortcutContext();

    function onClick() {
        setIsKeyboardShortcutsGuideModalOpen(true);
    }

    return (
        <FlexBox alignItems="center">
            <Link withIcon component="button" onClick={onClick}>
                <KeyboardIcon width={23} height={24} aria-hidden="true" className={styles.icon} />
                <Typography ml={"3"}>{t(messages.keyboardShortcutsLinkButton.id)}</Typography>
            </Link>
        </FlexBox>
    );
}

KeyboardShortcutsButton.displayName = "KeyboardShortcutsButton";
