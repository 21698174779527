import { CimDoc } from "@design-stack-vista/cdif-types";
import { filterNonServerRenderableItems } from "@six/features/UploadsAndAssets";

export function toShareableDocument(cimDoc: CimDoc | undefined) {
    if (!cimDoc) {
        return undefined;
    }

    return filterNonServerRenderableItems(cimDoc);
}
