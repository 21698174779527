import { DownloadFlowModal } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal";
import { OpenDownloadFlowButton } from "@six/experiments/digital/DownloadFlow/OpenDownloadFlowButton";
import React, { useCallback, useState } from "react";

export const DownloadFlow = () => {
    const [open, setOpen] = useState(false);

    const handleClick = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <OpenDownloadFlowButton onClick={handleClick} />
            {open && <DownloadFlowModal open={open} onClose={handleClose} />}
        </>
    );
};
