import type {
    BaseExtensionClassInterface,
    InteractiveDesignEngine
} from "@design-stack-vista/interactive-design-engine-core";
import {
    ItemLocksExtension,
    ItemMetadataExtension,
    ItemPermissionsExtension,
    ItemProcessingExtension,
    ItemSelectionExtension,
    ItemTemplateExtension
} from "@design-stack-vista/interactive-design-engine-core";
import {
    ImageCroppingExtension,
    ItemAspectRatioLockExtension,
    PanelLayoutExtension,
    PanelTextRecommendationExtension,
    RenderingBoundItemExtension,
    ItemMoveExtension,
    ItemPreviewExtension,
    DesignLayerItemVisibilityExtension,
    ItemOpacityExtension,
    PanelRulersExtension,
    EyeDropperPreviewExtension,
    PanelOrnamentsExtension,
    PanelZPatternOrderedItemsExtension,
    ItemLayoutExtension,
    ImageThreadColorsExtension,
    ItemResizeExtension,
    SubpanelResizeExtension,
    ItemRotateExtension,
    NudgeExtension,
    ColorPreviewOverridesItemExtension,
    PanelChromesExtension,
    CurvedTextExtension
} from "@design-stack-vista/core-features";
import { InlineCroppingExtension } from "@internal/feature-inline-cropping-core";
import {
    BackgroundColorExtension,
    ShapeAppearanceExtension,
    WordArtPreviewExtension,
    WordArtEditingExtension,
    TableMetadataExtension,
    OrderedTextListExtension,
    TextSpacingExtension
} from "@six/features/editorUI";
import { PanelUsedFontFamiliesExtension } from "@six/features/RecentFontFamilies";
import { PanelUsedColorsExtension } from "@internal/feature-recent-colors";
import { ImageColorsExtension } from "@internal/utils-color";
import { PanelHasPlaceholderTextExtension } from "@six/features/DesignReview";
import { StudioMetadataExtension } from "@internal/utils-custom-metadata";
import { ImageInstantUploadExtension } from "@six/features/UploadsAndAssets";
import { ImageAutoSharpenExtension } from "@six/features/editorUI/imageSharpening/ImageAutoSharpenExtension";
import { PanelSectionExtension } from "@internal/feature-panel-sections";
import { ItemAccessibilityExtension } from "@six/features/Accessibility";
import { ViewPanelExtension } from "@six/features/editorUI/viewPanel/extensions";
import { PanelSourceExtension } from "@shared/features/DesignPanel";
import { SingleColorImageExtension } from "@internal/feature-single-color";

export const STATIC_REQUIRED_EXTENSIONS: BaseExtensionClassInterface[] = [
    // Remove after https://vistaprint.atlassian.net/browse/DIYCP-171
    // is completed
    // https://vistaprint.atlassian.net/browse/AST-1828
    ItemLayoutExtension,
    ItemSelectionExtension,
    DesignLayerItemVisibilityExtension,
    PanelLayoutExtension,
    PanelSectionExtension,
    ViewPanelExtension,
    BackgroundColorExtension,
    ItemPreviewExtension,
    PanelChromesExtension,
    PanelUsedFontFamiliesExtension,
    PanelUsedColorsExtension,
    PanelTextRecommendationExtension,
    PanelHasPlaceholderTextExtension,
    ItemTemplateExtension,
    ImageColorsExtension,
    ItemProcessingExtension,
    ItemMoveExtension,
    ShapeAppearanceExtension,
    ItemMetadataExtension,
    ItemAspectRatioLockExtension,
    ItemLocksExtension,
    ItemPermissionsExtension,
    ImageCroppingExtension,
    RenderingBoundItemExtension,
    PanelOrnamentsExtension,
    PanelRulersExtension,
    PanelZPatternOrderedItemsExtension,
    ImageThreadColorsExtension,
    InlineCroppingExtension,
    ItemOpacityExtension,
    ItemResizeExtension,
    ItemRotateExtension,
    EyeDropperPreviewExtension,
    WordArtPreviewExtension,
    WordArtEditingExtension,
    StudioMetadataExtension,
    SubpanelResizeExtension,
    TableMetadataExtension,
    OrderedTextListExtension,
    ImageInstantUploadExtension,
    ImageAutoSharpenExtension,
    ItemAccessibilityExtension,
    PanelSourceExtension,
    SingleColorImageExtension,
    NudgeExtension,
    ColorPreviewOverridesItemExtension,
    TextSpacingExtension,
    CurvedTextExtension
];

export function decorateDesignEngineWithExtensions(designEngine: InteractiveDesignEngine) {
    STATIC_REQUIRED_EXTENSIONS.forEach(extension => {
        try {
            designEngine.designExtensionSystem.addExtension(extension);
        } catch (e) {
            // the extension must have been added already
        }
    });
}

export function cleanUpDesignEngineExtensions(designEngine: InteractiveDesignEngine) {
    STATIC_REQUIRED_EXTENSIONS.forEach(extension => {
        designEngine.designExtensionSystem.removeExtension(extension);
    });
}
