import React from "react";
import { Box, Button, FlexBox, H3, Link } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { SelectedCheckIcon } from "@internal/ui-studio-chrome";
import * as styles from "./downloadFlowModalSuccessView.module.scss";

export type DownloadFlowModalSuccessViewProps = {
    format: string;
    onClose: () => void;
};

const messages = defineMessages({
    downloadFlowModalSuccessHeader: {
        id: "studioSix.experiments.digital.downloadFlowModal.success.header",
        defaultMessage: "Your design is saved as [[format]]"
    },
    downloadFlowModalSuccessRetryLabel: {
        id: "studioSix.experiments.digital.downloadFlowModal.success.downloadRetryButton.label",
        defaultMessage: "click to retry your download"
    },
    downloadFlowModalSuccessBackButtonText: {
        id: "studioSix.experiments.digital.downloadFlowModal.success.backButton.text",
        defaultMessage: "Back to editing"
    },
    downloadFlowModalSuccessHomeButtonText: {
        id: "studioSix.experiments.digital.downloadFlowModal.success.homeButton.text",
        defaultMessage: "Go to homepage"
    }
});

export const DownloadFlowModalSuccessView = (props: DownloadFlowModalSuccessViewProps) => {
    const { format, onClose } = props;
    const { t } = useTranslationSSR();

    return (
        <FlexBox alignItems="center" className={styles.hFull} flexDirection="column" gap={"4"} justifyContent="center">
            <FlexBox
                alignItems="center"
                gap={"5"}
                flexDirection="column"
                justifyContent="center"
                pb={"4"}
                textAlign="center"
            >
                <SelectedCheckIcon className={styles.checkmark} />
                <Box>
                    <H3 mb={"4"}>{t(messages.downloadFlowModalSuccessHeader.id, { format: format.toUpperCase() })}</H3>
                    {/* <FlexBox>
                        <Trans i18nKey="downloadFlowModalSuccessSubheader">
                            <Typography fontSize="x">If your download hasn&apos;t started yet,&nbsp;</Typography>
                            <Button
                                skin="link"
                                render={props => (
                                    <Link {...props} aria-label={t(messages.downloadFlowModalSuccessRetryLabel.id)} to="/" />
                                )}
                            >
                                click here
                            </Button>
                        </Trans>
                    </FlexBox> */}
                </Box>
            </FlexBox>
            <Button
                aria-label={t(messages.downloadFlowModalSuccessBackButtonText.id)}
                width="full-width"
                onClick={onClose}
            >
                {t(messages.downloadFlowModalSuccessBackButtonText.id)}
            </Button>
            <Button
                skin="primary"
                width="full-width"
                render={props => (
                    <Link {...props} aria-label={t(messages.downloadFlowModalSuccessHomeButtonText.id)} to="/" />
                )}
            >
                {t(messages.downloadFlowModalSuccessHomeButtonText.id)}
            </Button>
        </FlexBox>
    );
};
