import React, { Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import { StudioHeader, MiniLogo } from "@vp/diy-editor-ui";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { FlexBox, Hidden } from "@vp/swan";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { MyProjectsButton } from "@shared/features/MyProjects";
import { SaveStatusButton } from "@shared/features/Save";
import { useOnSaveStatus } from "@six/features/Save";
import { ChangeTemplateButton, useShowChangeTemplate } from "@shared/features/ChangeTemplate";
import { observer } from "mobx-react-lite";
import { PreviewButton, useProductHasPreviewButton } from "@shared/features/Previews";
import { isDebugMode, isCareAgent } from "@internal/utils-browser";
import { lazily } from "react-lazily";
import { lazyRetry } from "@internal/utils-network";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { FileButton } from "@shared/features/GlobalHeader";
import {
    ChangeSizeButton,
    FlexibilityDesignAttributes,
    useActiveFlexibilityOptions,
    useMultiSizeProduct
} from "@shared/features/Flexibility";
import { useAppSelector } from "@shared/redux";
import { HistoryToolbar } from "@six/features/editorUI";
import { useStudioFlexibility } from "@internal/ab-test-studio-flexibility";
import { useIsFlagEnabled } from "@internal/utils-flags";
import { MailingButton } from "@six/features/MailingServices/MailingButton";
import { useIsMailingServicesCompatibleProduct } from "@shared/features/MailingServices";
import { useCartContext } from "@internal/utils-cart";
import { hasRelatedAccessoryInCart } from "@internal/data-access-cart";
import { DownloadFlow } from "@six/experiments/digital/DownloadFlow";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { SpecsAndTemplatesButton, useSpecsAndTemplatesOnGlobalHeader } from "@shared/features/SpecsAndTemplates";
import { ChangeSizeButtonTrackingShell } from "src/studioSix/defaultExperience/layout/components/globalHeader/ChangeSizeButtonTrackingShell";
import { useIsEmbroidery } from "@internal/utils-deco-tech";
import { useChangeSizeButton } from "@internal/ab-test-change-size-button";
import { useChangeSizePlacementDesktopButton } from "@internal/ab-test-change-size-button-placement-desktop";
import { useCreateLogoData, useStudioConfigurationManager } from "@internal/dex";
import { EmbroideryTipsButton } from "@six/features/Embroidery";
import { GlobalHeaderNextStepButton } from "./NextStep/GlobalHeaderNextStepButton";
import { ProjectNameDropdown } from "./ProjectNameDropdown";
import * as styles from "./GlobalHeader.module.scss";

const { StudioSixDebugTools } = lazily<{ StudioSixDebugTools: React.ComponentType<{}> }, any>(() =>
    lazyRetry(() => import("@six/features/Debug"))
);

const { DesignHelpWrapper } = lazily<{ DesignHelpWrapper: React.ComponentType<{}> }, any>(() =>
    lazyRetry(() => import("@six/features/DesignHelp"))
);

const { CopyDesignButton } = lazily<{ CopyDesignButton: React.ComponentType<{}> }, any>(() =>
    lazyRetry(() => import("@six/features/CopyDesign"))
);

const messages = defineMessages({
    headerIconAriaLabel: {
        id: "packagingStudio.components.header.headerIcon",
        defaultMessage: "Homepage",
        description: {
            note: "alt text for icon to return to the homepage"
        }
    }
});

const HeaderProjectOptions = observer(() => {
    const designEngine = useAvailableDesignEngine();
    const onSaveClick = useOnSaveStatus();
    const { isSmall, isMedium } = useStudioLayout();
    const { shouldAllowUserSave, shouldAllowMyProjects } = useStudioConfigurationManager().data;

    if (!designEngine) {
        return (
            <>
                <Skeleton width="120px" height="20px" style={{ borderRadius: "25px" }} />
                <span className={styles.verticalDivider} />
                <Skeleton width="90px" height="20px" style={{ borderRadius: "25px" }} />
            </>
        );
    }

    if (isSmall) {
        return null;
    }

    return (
        <>
            <Hidden sm>{shouldAllowMyProjects && <MyProjectsButton className={styles.buttonText} hideIcon />}</Hidden>
            <ProjectNameDropdown />
            {!isMedium && shouldAllowUserSave && (
                <>
                    <span className={styles.verticalDivider} />
                    <SaveStatusButton onSaveClick={onSaveClick} />
                </>
            )}
            <>
                <span className={styles.verticalDivider} />
                <HistoryToolbar />
            </>
        </>
    );
});

export const GlobalHeader = observer(() => {
    const { t } = useTranslationSSR();
    const { bookendsHeader } = usePageContext();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const showChangeTemplateBtn = useShowChangeTemplate();
    const { isSmall } = useStudioLayout();
    const { isDesignAttributeActive } = useActiveFlexibilityOptions();
    const isChangeSizeActive = isDesignAttributeActive(FlexibilityDesignAttributes.Size);
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isAnyFlexibilityFeatureEnabled = isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled;
    const showChangeSizeBtn = !isAnyFlexibilityFeatureEnabled && isChangeSizeActive;
    const hasPreviewButton = useProductHasPreviewButton();
    const isMailingServicesCompatible = useIsMailingServicesCompatibleProduct();
    const shouldShowDigitalDownload = useIsFlagEnabled("digitalDownload");
    const allowCopyDesign = useIsFlagEnabled("advancedTools") || isCareAgent();
    const showSpecsAndTemplates = useSpecsAndTemplatesOnGlobalHeader();
    const isEmbroidery = useIsEmbroidery();
    const { isMultiSizeProduct } = useMultiSizeProduct();
    const { isChangeSizeButtonABEnabled, isTrackingComplete: isTCSizeButton } = useChangeSizeButton();
    const { isChangeSizeButtonPlacementDesktopABEnabled, isTrackingComplete: isTCPlacementDesktop } =
        useChangeSizePlacementDesktopButton();

    // Holiday 2023 bandaid to prevent change-size from being used on holiday cards which have a matching envelope in cart
    // Because there's an issue with the envelopes flow that doesn't give customers the correctly sized envelope in that case
    const { itemsInCart } = useCartContext();
    const { workId } = useProductAndProjectStateManager().data;
    const hasRelatedCartItem = workId ? hasRelatedAccessoryInCart(itemsInCart, workId) : false;

    const configuredLogoData = useCreateLogoData({ initialBookendsLogo: bookendsHeader?.logo });
    const isABTestsTrackingCompleted = isTCSizeButton || isTCPlacementDesktop;
    const isChangeSizeButtonABTestEnabled = isChangeSizeButtonPlacementDesktopABEnabled || isChangeSizeButtonABEnabled;
    const isShowChangeSizeBtn =
        showChangeSizeBtn &&
        !hasRelatedCartItem &&
        isABTestsTrackingCompleted &&
        !isChangeSizeButtonABTestEnabled &&
        !isMultiSizeProduct;

    return (
        <div>
            {isDebugMode() && (
                <Suspense fallback={<></>}>
                    <StudioSixDebugTools />
                </Suspense>
            )}
            <StudioHeader
                data-testid="globalToolbar"
                className={styles.header}
                leadingNode={
                    <>
                        <MiniLogo
                            className={styles.miniLogo}
                            logo={configuredLogoData}
                            href={bookendsHeader?.url}
                            accessibleText={t(messages.headerIconAriaLabel.id)}
                        />
                        <HeaderProjectOptions />
                    </>
                }
                trailingNode={
                    <FlexBox alignItems="center" gap={"3"}>
                        {isEmbroidery && <EmbroideryTipsButton />}
                        {showSpecsAndTemplates && <SpecsAndTemplatesButton useHeaderButton />}
                        {isMailingServicesCompatible && <MailingButton />}
                        <ChangeSizeButtonTrackingShell
                            hasRelatedCartItem={hasRelatedCartItem}
                            showChangeSizeBtn={showChangeSizeBtn}
                            isMultiSizeProduct={isMultiSizeProduct}
                        />
                        {/* The below !hasRelatedCartItem check is a Holiday 2023 bandaid */}
                        {isShowChangeSizeBtn && <ChangeSizeButton />}
                        {showChangeTemplateBtn && <ChangeTemplateButton />}
                        {hasPreviewButton && <PreviewButton />}
                        {allowCopyDesign && (
                            <Suspense fallback={<></>}>
                                <CopyDesignButton />
                            </Suspense>
                        )}
                        {/* Behind a feature flag as a POC for the Doge squad (DOGE-1120) */}
                        {shouldShowDigitalDownload ? (
                            <DownloadFlow />
                        ) : (
                            <GlobalHeaderNextStepButton mobileLayout={false} />
                        )}
                    </FlexBox>
                }
                logoSmallScreen={
                    <MiniLogo
                        className={styles.mobileLogo}
                        logo={configuredLogoData}
                        href={bookendsHeader?.url}
                        accessibleText={t(messages.headerIconAriaLabel.id)}
                    />
                }
                // `DesignHelpWrapper` should only be rendered once, otherwise the Salesforce script will be initialized multiple times which can lead to console errors.
                // See: https://cimpress.slack.com/archives/CCG49C4F9/p1679060678951879
                leadingNodeSmallScreen={
                    isSmall && (
                        <>
                            <FileButton />
                            {easelLoaded && (
                                <Suspense fallback={<></>}>
                                    {" "}
                                    <DesignHelpWrapper />
                                </Suspense>
                            )}
                        </>
                    )
                }
                trailingNodeSmallScreen={
                    shouldShowDigitalDownload ? <DownloadFlow /> : <GlobalHeaderNextStepButton mobileLayout={true} />
                }
            />
        </div>
    );
});
