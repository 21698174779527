import React, { ReactNode, RefObject } from "react";
import { Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ViewIcon, ExpandingButton } from "@internal/ui-studio-chrome";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useExpandingButtonCallback } from "../ExpandingButton";
import * as styles from "./ViewButton.module.scss";

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: "easel.ui.expandingButton.closeButtonAriaLabel",
        defaultMessage: "Close",
        description: {
            note: "Aria label for accessibility of close button of expanding button popper"
        }
    }
});

interface ViewButtonProps {
    content: ReactNode;
    title: string;
}

export const ViewButton = ({ content, title }: ViewButtonProps, ref: RefObject<HTMLDivElement>) => {
    const { trackEvent } = useTrackEvents();
    const { t } = useTranslationSSR();
    const { buttonCallback } = useExpandingButtonCallback();

    return (
        <ExpandingButton
            className={styles.guidesButtonContainer}
            title={title}
            content={content}
            onOpen={() => {
                trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_VIEW_BUTTON });
            }}
            isDisabled={false}
            showArrow={false}
            displayType="singlerow"
            showCloseButton={true}
            closeButtonAriaLabel={t(messages.closeButtonAriaLabel.id)}
            buttonCallback={buttonCallback}
            data-testid="view-footer-button"
        >
            <ViewIcon aria-hidden={true} className={styles.viewIcon} />
            <Typography fontSize="xsmall" className={styles.guidesButtonLabel}>
                {title}
            </Typography>
        </ExpandingButton>
    );
};
ViewButton.displayName = "ViewButton";
