import { useEffect, useMemo, useState } from "react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useStudioLayout } from "@internal/feature-responsive-design";

// Specs and Template information not available for these products
const ExcludedPRDsList = [
    "PRD-TKFIQQ2WD", // 18 Oz. James Glass Bottle
    "PRD-GQE5EMKEV" // NA Custom Water Bottles
];

const IncludedPRDsList = new Set([
    // NA Signage
    "PRD-R1AJEBVL", // Yard Signs - NA
    "PRD-6YLYJS8P", //  NA Canvas Prints
    "PRD-BFBL4NNO", // Mesh Banners - NA
    "PRD-LQFILNUZ", // Fabric Banners - NA
    "PRD-NLRHYQSJ", // Retractable Banners - NA
    "PRD-XKHDONHL", // Vinyl Banners - NA
    "PRD-1ZSR5ZFEY", // Custom License Plates - NA
    "PRD-9AHTAPOZ4", // Step and Repeat Banners - NA
    "PRD-KPIMAABYJ", // Corrugated A-Frame - NA
    "PRD-1Z57IGIGH", // Double Sided Vinyl Banners - NA
    "PRD-LJE2OYSE", // Canvas Signs - NA
    "PRD-D9GCGYMH4", // NA Easel Back Canvas
    "PRD-T2OU2YBXG", // Tension Fabric Display
    "PRD-SQJSRHWR", // Canopy Tents - NA
    "PRD-SDNLKFBO", // NA Pop-Up Displays (With Frame)

    // NA Packaging
    "PRD-VOUYGJWEQ", // NA Poly Mailers
    "PRD-ILLQYZVRN", // NA Wrapping Paper
    "PRD-3QW5AAJVW", // Pratt - Pizza Box NA
    "PRD-LXRIVJKMX", // NA Crinkle Fill
    "PRD-F9E1JBVCQ", // NA Tissue Paper
    "PRD-W3XZSWNPL", // NA Ribbon
    "PRD-HGZV632H", // NA Packaging Tape
    "PRD-ZLGVDUDCB", // NA Budget Paper Bags
    "PRD-LPWU6N7PV", // NA Custom Pouches Sample Kit
    "PRD-7ZLXV6GGY", // NA Shipping Envelopes
    "PRD-KHIZFFHRD", // NA Paper Cups
    "PRD-VT52LFEY", // NA Hang Tags
    "PRD-7F2ZJYVG0", // NA Square Mailer Boxes Samples
    "PRD-YCATFRJPX", // NA Flat Shipping Box Sample
    "PRD-B1DXJBDGE", // NA Folded Hang Tags
    "PRD-EVFTJXJOU", // NA Custom Coffee Sleeve
    "PRD-LODIGGXPZ", // NA Poly Tape Poly Tape is an adhesive tape used for packaging
    "PRD-D3NQHZMKZ", // NA Gable Boxes Samples
    "PRD-4QDKO6DHP", // NA Tuck Top Mailer Boxes SAMPLE

    "PRD-VJUVVLHSM", // NA Premium Full Color Paper Bag
    "PRD-ZZ71KV7FY", // NA Luxury Gift Bags
    "PRD-MGWN9LOYK",
    "PRD-U5XQ7S1K",
    "PRD-MH6JQEPUQ",
    "PRD-YWGTLOGNW",

    "PRD-F0X11PZ3Q", // Mailer Boxes
    "PRD-Y1LEPL3MX", // Shipping Boxes
    "PRD-IRA9J9ZYN", // Product Boxes
    "PRD-RVNI6YXTA", // Pillow Boxes
    "PRD-CNJNMBZX9", // Stand up Pouches
    "PRD-KDCYEUDEL", // Flat Pouches
    "PRD-1O9IXZOA9", // Top Tuck Box
    "PRD-WQ476CAP7", // Square Mailer
    "PRD-QYSK5N7CE", // Top Tuck Mailer Box
    "PRD-QFZMXW6MA", // Flat Shipping Box
    "PRD-OUVYY2FRC", // Lock Bottom Boxes
    "PRD-MDOVMP6NL", // Plastic Cups
    "PRD-UAPTBKAXO", // Custom Gable Box
    "PRD-ASIEDCOUH", // Auto Lock Bottom Boxes
    // EU
    "PRD-1NYANXIDO", // Shipping Boxes
    "PRD-HQQHRNYV", // Product Boxes
    "PRD-YPOBNRUF", // Pillow Boxes
    "PRD-ERH5MYDSB", // Pull Out Boxes
    "PRD-N9KVUFGCQ", // Postal Boxes
    "PRD-ATRKCP22", // Stand Up Pouches
    "PRD-70BBAGZUB", // Flat Pouches
    "PRD-RMLMHJKSE", // Recyclable Pouches
    "PRD-YPIVR9IK7", // Paper Cups
    "PRD-CTB5UVMPP", // Flip Lid Boxes
    "PRD-WOPGFEB2F", // Ice Cream Cups
    "PRD-2YDCCDI3S", // Boxes with Handles
    "PRD-S7USUOJFN", // Book Boxes
    "PRD-UN2AAJKLC", // Lock Bottom Boxes
    "PRD-QO9EMBUDQ", // Cardboard Takeaway Boxes
    "PRD-ULVYR5LYL", // Cardboard Food Trays
    "PRD-U5YTQTRQV", // Full Print Paper Bags
    // AU
    "PRD-YPKEDTODB", // Mailer Box New
    "PRD-I7GQ8DI9C", // Wine Boxes
    // IN
    "PRD-NXPTD4KVV", // Product Boxes
    "PRD-N4LUWYYOP", // Stand Up Custom Pouches
    "PRD-WLO434QXK" // Flat Pouches
]);

export const useProductHasSpecsAndTemplates = () => {
    const { productKey, isFullBleed } = useProductAndProjectStateManager().data;
    const showSpecsAndTemplateInGlobalHeader = useSpecsAndTemplatesOnGlobalHeader();

    const [hasSpecsAndTemplate, setHasSpecsAndTemplate] = useState<boolean>(false);

    useEffect(() => {
        if (!isFullBleed || ExcludedPRDsList.indexOf(productKey) > 0 || showSpecsAndTemplateInGlobalHeader) {
            return;
        }
        setHasSpecsAndTemplate(true);
    }, [productKey, isFullBleed, showSpecsAndTemplateInGlobalHeader]);

    return hasSpecsAndTemplate;
};

/*
    Showing specs-and-templates button on Global header for packaging products and listed signage products.
    For other products, it is shown in the My Projects section.

    As we want the SpecsAndTemplate button to be shown on packaging product and listed signage products, did not
    necessity to add in `enable3DExperience` config, as it would have enabled for all the 
    3D supported products
*/
export const useSpecsAndTemplatesOnGlobalHeader = () => {
    const { isLarge } = useStudioLayout();
    const { productKey } = useProductAndProjectStateManager().data;

    const showSpecsAndTemplate = useMemo(() => isLarge && IncludedPRDsList.has(productKey), [isLarge, productKey]);

    return showSpecsAndTemplate;
};
