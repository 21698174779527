import { Command } from "@design-stack-vista/cimdoc-state-manager";
import { EYEDROPPER_END_EVENT, EYEDROPPER_START_EVENT } from "@design-stack-vista/core-features";
import { CommandAPI, HISTORY_REDO_EVENT, HISTORY_UNDO_EVENT } from "@design-stack-vista/interactive-design-engine-core";
import { STUDIO_DIAGNOSTIC_EVENTS } from "@shared/utils/Tracking";
import { ImageType } from "@shared/features/UploadsAndAssets";
import { getBooleanData } from "@internal/utils-standard-lib";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import {
    addImage,
    addShape,
    addTextArea,
    addWordArtItem,
    CommandNames,
    removeItem,
    removeItems,
    replaceImage,
    cleanPlaceholderImage
} from "../editorUI/commands";
import { classifyImage } from "./classifyImage";

// These typings exist to ensure lookups are done via the CommandNames enum and to infer correct types for arguments passed to the "getExtraData" methods
interface CommandTrackingData<T extends Command<any, any> = Command<any, any>> {
    eventDetail: string;
    label: string;
    getExtraData?: (result: CommandAPI<Parameters<T>[1], ReturnType<T>>) => any;
}

export const commandEvents: {
    [CommandNames.ADD_IMAGE]: CommandTrackingData<typeof addImage>;
    [CommandNames.ADD_SHAPE]: CommandTrackingData<typeof addShape>;
    [CommandNames.ADD_TEXTAREA]: CommandTrackingData<typeof addTextArea>;
    [CommandNames.ADD_WORDART]: CommandTrackingData<typeof addWordArtItem>;
    [CommandNames.REMOVE_ITEM]: CommandTrackingData<typeof removeItem>;
    [CommandNames.REMOVE_ITEMS]: CommandTrackingData<typeof removeItems>;
    [CommandNames.REPLACE_IMAGE]: CommandTrackingData<typeof replaceImage>;
    [CommandNames.CLEAR_PLACEHOLDER_IMAGE]: CommandTrackingData<typeof cleanPlaceholderImage>;
} = {
    [CommandNames.ADD_IMAGE]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.ADD_IMAGE,
        label: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.eventLabel,
        getExtraData: ({ input }) => {
            const { image, dclMetadata, studioMetadata } = input;

            const imageInfo = classifyImage({ image, dclMetadata, studioMetadata });

            let eventDetail = STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.ADD_IMAGE;

            if (imageInfo.imageType === ImageType.Noun) {
                eventDetail = STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.ADD_ICON;
            }

            return {
                eventDetail,
                itemType: ItemTypes.IMAGE,
                mimeType: dclMetadata?.mimeType,
                ...imageInfo
            };
        }
    },
    [CommandNames.ADD_SHAPE]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.SHAPE.events.ADD,
        label: STUDIO_DIAGNOSTIC_EVENTS.SHAPE.eventLabel,
        getExtraData: ({ input }) => {
            const { addShapeParameters, shapeOptions } = input;
            const shapeType = shapeOptions?.shape?.type || addShapeParameters.shape.type;

            // default to 0 for parity with same event in studio 5 when there's no rotation
            const { rotationAngle } = addShapeParameters.shape || 0;

            return {
                shapeType,
                itemType: ItemTypes.SHAPE,
                shapeMetadata: shapeOptions?.shape,
                rotation: rotationAngle
            };
        }
    },
    [CommandNames.ADD_TEXTAREA]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.TEXT.events.ADD_TEXT,
        label: STUDIO_DIAGNOSTIC_EVENTS.TEXT.eventLabel,
        getExtraData: () => ({ itemType: ItemTypes.TEXT })
    },
    [CommandNames.ADD_WORDART]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.TEXT.events.ADD_WORD_ART,
        label: STUDIO_DIAGNOSTIC_EVENTS.TEXT.eventLabel,
        getExtraData: () => ({ itemType: ItemTypes.WORDART })
    },
    [CommandNames.REMOVE_ITEM]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.ITEM.events.DELETE,
        label: STUDIO_DIAGNOSTIC_EVENTS.ITEM.eventLabel
    },
    [CommandNames.REMOVE_ITEMS]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.ITEM.events.DELETE,
        label: STUDIO_DIAGNOSTIC_EVENTS.ITEM.eventLabel
    },
    [CommandNames.REPLACE_IMAGE]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.REPLACE_IMAGE,
        label: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.eventLabel,
        getExtraData: ({ input }) => {
            const { studioMetadata } = input;

            return {
                isLogo: getBooleanData(studioMetadata?.analysis?.isLogo),
                isPhoto: getBooleanData(studioMetadata?.analysis?.isPhoto)
            };
        }
    },
    [CommandNames.CLEAR_PLACEHOLDER_IMAGE]: {
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.PLACEHOLDER.events.DELETE,
        label: STUDIO_DIAGNOSTIC_EVENTS.PLACEHOLDER.eventLabel
    }
};

export const genericEvents = [
    {
        eventName: HISTORY_UNDO_EVENT,
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.events.UNDO,
        label: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.eventLabel
    },
    {
        eventName: HISTORY_REDO_EVENT,
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.events.REDO,
        label: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.eventLabel
    },
    {
        eventName: EYEDROPPER_START_EVENT,
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.events.START,
        labelName: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.eventLabel
    },
    {
        eventName: EYEDROPPER_END_EVENT,
        eventDetail: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.events.END,
        labelName: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.eventLabel
    }
];
