import React, { Suspense, useRef, useState, useSyncExternalStore } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle
} from "@vp/swan";
import { ErrorBoundary } from "react-error-boundary";
import { DownloadFlowModalSuccessView } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/DownloadFlowModalSuccessView";
import { DownloadFlowModalBody } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/DownloadFlowModalBody";
import { DownloadFlowLoadingPanel } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/DownloadFlowLoadingPanel";
import { ToggleSaveDownloadConfig } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/ToggleSaveDownloadConfig";
import { DownloadConfig, DownloadFormats, Resolution } from "@six/experiments/digital/DownloadFlow/types";
import { DigitalDownloadButton } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/DigitalDownloadButton";
import { DownloadFlowErrorPanel } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/DownloadFlowErrorPanel";
import { getConfig, getDimensions } from "@six/experiments/digital/DownloadFlow/utils";
import { useDesignRequirementsContext } from "@shared/features/Product";
import {
    store as downloadSettingsStore,
    isShowSaveSettingsToggleAvailable
} from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/downloadSettings";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import * as styles from "./downloadFlowModal.module.scss";

export type DownloadFlowModalProps = {
    open: boolean;
    onClose: () => void;
};

const messages = defineMessages({
    downloadFlowModalLabel: {
        id: "studioSix.experiments.digital.downloadFlowModal.label",
        defaultMessage: "Select the format you want to download your design as"
    },
    downloadFlowModalCloseButtonText: {
        id: "studioSix.experiments.digital.downloadFlowModal.closeButton.text",
        defaultMessage: "Close"
    },
    downloadFlowModalHeader: {
        id: "studioSix.experiments.digital.downloadFlowModal.header",
        defaultMessage: "Download Options"
    }
});

export const DownloadFlowModal = observer((props: DownloadFlowModalProps) => {
    const { open, onClose } = props;
    const { t } = useTranslationSSR();
    const savedDownloadSettings = useSyncExternalStore(
        downloadSettingsStore.subscribe,
        downloadSettingsStore.getSnapshot
    );
    const { workName, productName } = useProductAndProjectStateManager().data;
    const abortControllerRef = useRef<AbortController | null>(null);
    const designRequirements = useDesignRequirementsContext();
    const panel = designRequirements?.panels[0];
    const defaultConfig = { filename: workName || productName, ...getDimensions(panel) };
    const [downloadConfig, setDownloadConfig] = useState<DownloadConfig>(
        savedDownloadSettings ?? getConfig(defaultConfig, "png")
    );
    const [isSuccess, setIsSuccess] = useState(false);

    /**
     * - when a user opens the download settings drawer and the "save download settings" toggle is off, they will see the default selections
     * - when a user toggles the "save download settings" from the default state, the current selection will be saved to local storage and every time
     *   they open the drawer they will see the selections that are persisted in local storage
     * - when a user changes their selection while the "save download settings" toggle is on, then on every click the local storage will be updated
     * - when a user turns the "save download settings" toggle off, then the local storage will be cleared and the next time the user opens the downloads
     *   drawer they will be shown the default selections
     */

    const handleCancel = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort({ message: "Cancel download", abort: true });
            abortControllerRef.current = null;
        }
    };

    const showDownloadSuccess = () => setIsSuccess(true);

    const handleChangeFormats = (selectedValue: DownloadFormats): void => {
        const newDownloadConfig = getConfig({ ...downloadConfig, ...getDimensions(panel) }, selectedValue);

        setDownloadConfig(newDownloadConfig);
        downloadSettingsStore.update(newDownloadConfig);
    };

    const handleChangeResolution = (resolution: Resolution): void => {
        if (resolution) {
            const newDownloadConfig = {
                ...downloadConfig,
                resolution
            };

            setDownloadConfig(newDownloadConfig);
            downloadSettingsStore.update(newDownloadConfig);
        }
    };

    const handleTransparentBackground = (value: boolean) => {
        const newDownloadConfig =
            downloadConfig.format === "png" ? { ...downloadConfig, isTransparent: value } : downloadConfig;

        setDownloadConfig(newDownloadConfig);
        downloadSettingsStore.update(newDownloadConfig);
    };

    const handleSaveDownloadSettings = (activated: boolean) => {
        downloadSettingsStore.update(activated ? downloadConfig : null);
    };

    return (
        <ModalDialog isOpen={open} variant="panel-right" onRequestDismiss={onClose}>
            <ModalDialogContent
                aria-labelledby={t(messages.downloadFlowModalLabel.id)}
                className={styles.downloadModal}
            >
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText={t(messages.downloadFlowModalCloseButtonText.id)} />
                </ModalDialogNav>
                <ErrorBoundary
                    fallbackRender={({ resetErrorBoundary }) => <DownloadFlowErrorPanel onRetry={resetErrorBoundary} />}
                >
                    <Suspense fallback={<DownloadFlowLoadingPanel onCancel={handleCancel} />}>
                        {!isSuccess && (
                            <ModalDialogHeader>
                                <ModalDialogTitle>{t(messages.downloadFlowModalHeader.id)}</ModalDialogTitle>
                            </ModalDialogHeader>
                        )}
                        <ModalDialogBody className={styles.flex1} mb={0}>
                            {isSuccess ? (
                                <DownloadFlowModalSuccessView format={downloadConfig.format} onClose={onClose} />
                            ) : (
                                <DownloadFlowModalBody
                                    downloadConfig={downloadConfig}
                                    setTransparentBackground={handleTransparentBackground}
                                    changeFormats={handleChangeFormats}
                                    changeSizes={handleChangeResolution}
                                />
                            )}
                        </ModalDialogBody>
                        {!isSuccess && (
                            <ModalDialogFooter pinned>
                                {isShowSaveSettingsToggleAvailable() && (
                                    <ToggleSaveDownloadConfig
                                        activated={!!savedDownloadSettings}
                                        onChange={handleSaveDownloadSettings}
                                    />
                                )}
                                {downloadConfig && (
                                    <DigitalDownloadButton
                                        config={downloadConfig}
                                        showDownloadSuccess={showDownloadSuccess}
                                        abortControllerRef={abortControllerRef}
                                    />
                                )}
                            </ModalDialogFooter>
                        )}
                    </Suspense>
                </ErrorBoundary>
            </ModalDialogContent>
        </ModalDialog>
    );
});
