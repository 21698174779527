import React from "react";
import { useAppDispatch, useAppSelector, setShowRulers } from "@shared/redux";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { ViewToggleRow } from "@shared/features/ViewPanel";
import { useScreenClass } from "@vp/swan";
import { RulersIcon } from "@internal/ui-studio-chrome";

const messages = defineMessages({
    rulers: {
        id: "editorUI.viewPanel.rulers",
        defaultMessage: "Rulers",
        description: {
            note: "Label for rulers toggle"
        }
    },
    rulersDescription: {
        id: "editorUI.viewPanel.rulersDescription",
        defaultMessage: "This is the size of your design.",
        description: {
            note: "Description for rulers toggle"
        }
    }
});

export function RulerToggle() {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const showRulers = useAppSelector(state => state.showRulers);
    const screenClass = useScreenClass();

    if (screenClass === "xs") {
        return null;
    }

    return (
        <ViewToggleRow
            icon={<RulersIcon />}
            label={t(messages.rulers.id)}
            labelId="rulersLabel"
            activated={showRulers}
            onRequestActivatedChange={activated => dispatch(setShowRulers(activated))}
            description={t(messages.rulersDescription.id)}
            data-testid="viewPanelToggle-RULER"
        />
    );
}
