import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, FlexBox, H3, Spinner, Typography } from "@vp/swan";
import React, { MouseEvent, useState } from "react";
import * as styles from "./downloadFlowLoadingPanel.module.scss";

export type DownloadLoadingPanelPropTypes = {
    onCancel?: () => void;
};

const messages = defineMessages({
    downloadFlowModalLoadingHeader: {
        id: "studioSix.experiments.digital.downloadFlowModal.loading.header",
        defaultMessage: "Preparing your design..."
    },
    downloadFlowModalLoadingSubheader: {
        id: "studioSix.experiments.digital.downloadFlowModal.loading.subheader",
        defaultMessage: "This will only take a moment"
    },
    downloadFlowModalLoadingAccessibleText: {
        id: "studioSix.experiments.digital.downloadFlowModal.loading.accessibleText",
        defaultMessage: "Loading"
    },
    downloadFlowModalLoadingCancelButtonText: {
        id: "studioSix.experiments.digital.downloadFlowModal.loading.cancelButton.text",
        defaultMessage: "Cancel download"
    }
});

export const DownloadFlowLoadingPanel = (props: DownloadLoadingPanelPropTypes) => {
    const { onCancel } = props;
    const { t } = useTranslationSSR();
    const [processingCancel, setProcessingCancel] = useState(false);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (onCancel) {
            onCancel();
            setProcessingCancel(true);
        }
    };

    return (
        <FlexBox alignItems="center" className={styles.hFull} flexDirection="column" gap={"6"} justifyContent="center">
            <FlexBox alignItems="center" flexDirection="column" justifyContent="center">
                {/* // Something in design help overrides the spinner animation tp have a white border, which has been reported */}
                <Spinner
                    accessibleText={t(messages.downloadFlowModalLoadingAccessibleText.id)}
                    aria-busy="true"
                    aria-live="polite"
                    role="alert"
                    pb={"5"}
                />
                <H3>{t(messages.downloadFlowModalLoadingHeader.id)}</H3>
                <Typography fontSize="standard">{t(messages.downloadFlowModalLoadingSubheader.id)}</Typography>
            </FlexBox>
            <Button
                aria-label={t(messages.downloadFlowModalLoadingCancelButtonText.id)}
                disabled={processingCancel}
                onClick={handleClick}
            >
                {t(messages.downloadFlowModalLoadingCancelButtonText.id)}
            </Button>
        </FlexBox>
    );
};
