import {
    type TemplateType,
    type AppDispatch,
    setCurrency,
    loadSurfaceUpsells,
    surfaceUpsellsLoaded,
    useAppDispatch,
    useAppSelector
} from "@shared/redux";
import { type SurfaceUpsell } from "@internal/data-access-calcifer";
import { convertDocumentSourceType, DocumentSourceType } from "@internal/data-access-design-specifications-service";
import { ERROR_CODES, useErrors } from "@internal/utils-errors";
import { type ErrorHandler } from "@internal/utils-errors";
import { getDifferentialPricing, isVatInclusive } from "@internal/data-access-pricing";
import {
    BLANK_SELECTED_TEMPLATE,
    CUSTOM_SELECTED_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE,
    DUPLICATE_FIRST_PANEL_TEMPLATE
} from "@internal/data-access-template";
import type { DSS } from "@vp/types-ddif";
import keyBy from "lodash/keyBy";
import once from "lodash/once";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useSurfaceUpsells } from "@shared/features/StudioBootstrap";
import { useAsyncEffect } from "@design-stack-vista/utility-react";

async function getPricing(
    productKey: string,
    quantity: number,
    productVersion: number,
    filteredSelectedProductOptions: { [x: string]: string },
    choiceGroups: {},
    dispatchPricingInfo: (currency: any) => void,
    handleError: ErrorHandler
) {
    try {
        const differentialPricingData = await getDifferentialPricing(
            productKey,
            filteredSelectedProductOptions,
            quantity,
            choiceGroups,
            productVersion
        );
        dispatchPricingInfo(differentialPricingData.currency);

        const priceToUse = isVatInclusive() ? "taxed" : "untaxed";

        return Object.entries(differentialPricingData.choiceGroups).reduce((accumulator, choice) => {
            // @ts-ignore FIXME: must handle implicit `any` type
            accumulator[choice[0]] = {
                // @ts-ignore FIXME: must handle implicit `any` type
                differentialDiscountPrice: choice[1].differentialDiscountPrice[priceToUse],
                // @ts-ignore FIXME: must handle implicit `any` type
                differentialListPrice: choice[1].differentialListPrice[priceToUse]
            };
            return accumulator;
        }, {});
    } catch (e) {
        handleError(e, ERROR_CODES.SURFACE_UPSELL_PRICING, ENTITY_CODE);
        return undefined;
    }
}

function determineSelectedTemplate(
    panelIsBlank: boolean,
    upsellPanelSource: DSS.DocumentPanelSource | undefined
): { selectedTemplate: TemplateType } {
    if (panelIsBlank) {
        return { selectedTemplate: BLANK_SELECTED_TEMPLATE };
    }
    if (upsellPanelSource) {
        switch (upsellPanelSource.source) {
            case convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN):
                return { selectedTemplate: upsellPanelSource.data as TemplateType };
            case convertDocumentSourceType(DocumentSourceType.EMPTY):
                return { selectedTemplate: CUSTOM_SELECTED_TEMPLATE };
            case convertDocumentSourceType(DocumentSourceType.LEGACY):
                // if a legacy template was selected, we can assume they've been offered this on the old platform
                // no need to offer it again
                return { selectedTemplate: CUSTOM_SELECTED_TEMPLATE };
            case convertDocumentSourceType(DocumentSourceType.FULLBLEED):
                return { selectedTemplate: FULLBLEED_SELECTED_TEMPLATE };
            case convertDocumentSourceType(DocumentSourceType.DUPLICATE):
                return { selectedTemplate: DUPLICATE_FIRST_PANEL_TEMPLATE };
            default:
                return { selectedTemplate: BLANK_SELECTED_TEMPLATE };
        }
    } else {
        return { selectedTemplate: CUSTOM_SELECTED_TEMPLATE };
    }
}

export const getSurfaceUpsellsAndPricing = async (
    document: DSS.DesignDocument,
    surfaceUpsells: SurfaceUpsell[] | undefined,
    studioSelectedProductOptions: Record<string, string>,
    productKey: string,
    productVersion: number,
    handleError: ErrorHandler,
    quantity: number,
    dispatch: AppDispatch,
    abortSignal: AbortSignal | undefined
) => {
    const dispatchPricingInfo = once(currency => {
        dispatch(setCurrency(currency));
    });

    try {
        if (surfaceUpsells && surfaceUpsells.length > 0) {
            const pricedSurfaceUpsells = await Promise.all(
                surfaceUpsells.map(async surfaceUpsell => {
                    if (surfaceUpsell.optionName) {
                        const { colorOption, blankOption } = surfaceUpsell;
                        if (colorOption === undefined || blankOption === undefined) {
                            throw Error("Color or blank option are not defined");
                        }

                        // We want to provide selected options for the lowest priced value for this attribute so we use blank
                        // we provide the color option as a choice group so we get the diff price increase for that option
                        const choiceGroups = {
                            [colorOption]: {
                                [surfaceUpsell.optionName]: colorOption
                            }
                        };
                        const newProductOptions = {
                            ...studioSelectedProductOptions,
                            [surfaceUpsell.optionName]: blankOption
                        };

                        const pricing = await getPricing(
                            productKey,
                            quantity,
                            productVersion,
                            newProductOptions,
                            choiceGroups,
                            dispatchPricingInfo,
                            handleError
                        );

                        // the selectedTemplate won't exist if the document was created in the control experience for the backside test
                        // we populate it here so that if a customer loads a work with a V1 document, the test experience isn't degraded
                        let upsellPanelSource = document.metadata?.documentSources?.panels.find(
                            panel => panel.id === surfaceUpsell.panelId
                        );

                        // This is to prevent backsides from disappearing in imported legacy documents before the panel.id was fixed
                        // in the import tool on 6/30/21.
                        if (!upsellPanelSource) {
                            let documentPanelsIndex = document.document.panels.findIndex(
                                panel => panel.id === surfaceUpsell.panelId
                            );

                            if (documentPanelsIndex === -1) {
                                documentPanelsIndex = document.document.panels.findIndex(
                                    panel => panel.name === surfaceUpsell.panelName
                                );
                            }
                            if (
                                documentPanelsIndex >= 0 &&
                                document.metadata?.documentSources?.panels[documentPanelsIndex]?.source ===
                                    convertDocumentSourceType(DocumentSourceType.LEGACY)
                            ) {
                                upsellPanelSource = document.metadata?.documentSources?.panels[documentPanelsIndex];
                            }
                        }

                        const panelIsBlank =
                            studioSelectedProductOptions[surfaceUpsell.optionName] === surfaceUpsell.blankOption;

                        const result = determineSelectedTemplate(panelIsBlank, upsellPanelSource);
                        const { selectedTemplate } = result;

                        return {
                            optionName: surfaceUpsell.optionName,
                            colorOption: surfaceUpsell.colorOption,
                            blankOption: surfaceUpsell.blankOption,
                            panelId: surfaceUpsell.panelId,
                            panelName: surfaceUpsell.panelName,
                            selectedTemplate,
                            pricing
                        };
                    }

                    return surfaceUpsell;
                })
            );

            if (abortSignal?.aborted) {
                return;
            }
            // now that we have pricing, update again
            dispatch(loadSurfaceUpsells(surfaceUpsells ? keyBy(pricedSurfaceUpsells, upsell => upsell.panelName) : {}));
        }
    } catch (e) {
        handleError(e, ERROR_CODES.SURFACE_UPSELL_LOAD, ENTITY_CODE);
    } finally {
        dispatch(surfaceUpsellsLoaded());
    }
};

export function useGetSurfaceUpsellsAndPricing(document: DSS.DesignDocument | undefined) {
    const { productKey, productVersion, quantity, studioSelectedProductOptions } =
        useProductAndProjectStateManager().data;
    const { handleError } = useErrors();
    const dispatch = useAppDispatch();
    const surfaceUpsells = useSurfaceUpsells();
    const pricingInitialized = useAppSelector(state => state.pricingInitialized);

    useAsyncEffect(
        helper => {
            if (pricingInitialized && document && productKey && productKey.length > 0) {
                getSurfaceUpsellsAndPricing(
                    document,
                    surfaceUpsells,
                    studioSelectedProductOptions,
                    productKey,
                    productVersion,
                    handleError,
                    quantity,
                    dispatch,
                    helper.abortSignal
                );
            }
        },
        [
            dispatch,
            handleError,
            productKey,
            productVersion,
            quantity,
            studioSelectedProductOptions,
            surfaceUpsells,
            document,
            pricingInitialized
        ]
    );
}
