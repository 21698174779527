import { useEffect } from "react";
import {
    CommandEvent as BaseCommandEvent,
    EXECUTE_COMMAND_EVENT
} from "@design-stack-vista/interactive-design-engine-core";
import { dsEmitter } from "@design-stack-vista/centralized-events";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { commandEvents, genericEvents } from "./designEngineEvents";
import { CommandNames } from "../editorUI/commands";

type CommandEvent = BaseCommandEvent<any, any> & { payload: { displayName: CommandNames } };
export function useSetupEventTracking() {
    const trackingClient = useTrackingClient();
    useEffect(() => {
        const cleanupCommandListeners = dsEmitter.on(EXECUTE_COMMAND_EVENT, ({ payload }: CommandEvent) => {
            if (!Object.values(CommandNames).includes(payload.displayName)) return;
            const { eventDetail, label, getExtraData } = commandEvents[payload.displayName];

            trackingClient.track(Events.StudioDiagnostic, {
                event: Events.StudioDiagnostic,
                eventDetail,
                label,
                extraData: () => getExtraData?.(payload)
            });
        });

        const cleanupGenericListeners = genericEvents.map(event => {
            const { eventName, eventDetail, label } = event;
            return dsEmitter.on(eventName, evt =>
                trackingClient.track(Events.StudioDiagnostic, {
                    event: Events.StudioDiagnostic,
                    eventDetail,
                    label
                })
            );
        });

        return () => {
            cleanupCommandListeners();
            cleanupGenericListeners.forEach(cleanup => cleanup());
        };
    }, [trackingClient]);
}
