import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { ViewButton, ViewPanelContainer } from "@shared/features/ViewPanel";
import { DesktopExperience } from "@internal/feature-responsive-design";
import { Divider } from "@vp/swan";
import { KeyboardShortcutsButton } from "@shared/features/KeyboardShortcuts";
import { ViewPanelContent } from "./ViewPanelContent";

const messages = defineMessages({
    viewPanel: {
        id: "editorUI.viewPanel.title",
        defaultMessage: "View",
        description: {
            note: "Title for the view panel"
        }
    }
});

export function ViewPanel() {
    const { t } = useTranslationSSR();

    return (
        <ViewButton
            title={t(messages.viewPanel.id)}
            data-testid="view-footer-button"
            content={
                <>
                    <ViewPanelContainer title={t(messages.viewPanel.id)}>
                        <ViewPanelContent />
                    </ViewPanelContainer>
                    <DesktopExperience>
                        <div>
                            <Divider marginTop={"6"} marginBottom={"5"} />
                            <KeyboardShortcutsButton />
                        </div>
                    </DesktopExperience>
                </>
            }
        />
    );
}

ViewPanel.displayName = "ViewPanel";
