import React, { useEffect, useMemo, useState } from "react";
import { AITemplatesProvider, LocalizedProvider as AiTemplatesLocalizedProvider } from "@internal/feature-ai-templates";
import { hideLoader, showLoader } from "@shared/redux";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useSpinDelay } from "spin-delay";
import { useDispatch } from "react-redux";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import {
    getWorkEntity,
    getWorkEntityFromSession,
    setWorkEntityInSession,
    type WorkEntity
} from "@internal/data-access-work-entity-service";
import { useAsyncEffect } from "@design-stack-vista/utility-react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { fireGenericTrackingEvent } from "@shared/utils/Tracking";
import { useStudioConfigurationManager } from "@internal/dex";
import { mapDesignAttributeToProductOption } from "@internal/utils-studio-configuration-core";
import { useDesignAttributeMappings } from "@shared/features/StudioBootstrap";
import { useUpdateProductWithNewDocument } from "./useUpdateProductWithNewDocument";
import { useAITemplatesLocalizations } from "./useAITemplatesLocalizations";

const messages = defineMessages({
    aiTemplateUpdating: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.recentlyUsedAITemplates.aiTemplateUpdating",
        defaultMessage: "Updating template...",
        description: {
            note: "Loading spinner for adding an AI Template to the IDA"
        }
    }
});

const SUBSTRATE_COLOR_KEY = "Substrate Color";

export const AITemplatesProviderWrapper = observer(({ children }: React.PropsWithChildren) => {
    const { t } = useTranslationSSR();
    const dispatch = useDispatch();
    const [aiTemplateUpdatingState, setAITemplateUpdatingState] = useState(false);
    const productAndProjectStateManager = useProductAndProjectStateManager();
    const updateProductAndProjectStateConfiguration = productAndProjectStateManager.updateConfiguration;
    const { isIdentityInitialized, auth, identity } = useIdentityContext();
    const { isDesignAssistantTabEnabled } = useStudioConfigurationManager().data;
    const { productKey, locale, productVersion, studioSelectedProductOptions, workId } =
        productAndProjectStateManager.data;
    const [designAssistantTemplatesSearchParams, setDesignAssistantTemplatesSearchParams] = useState<string>("");
    // When adding an AI Template to the canvas
    // only show the loader if it's taking longer than 0.5 seconds
    // Show the loader for at least 1 second
    const spinDelay = useSpinDelay(aiTemplateUpdatingState, {
        delay: 500,
        minDuration: 1
    });

    const designAttributeMappings = useDesignAttributeMappings();
    const aiTemplatesLocalizedValues = useAITemplatesLocalizations();
    const { updateProductWithNewDocument } = useUpdateProductWithNewDocument();

    const substrateColor = useMemo(() => {
        const optionKey = mapDesignAttributeToProductOption(designAttributeMappings, SUBSTRATE_COLOR_KEY);

        return studioSelectedProductOptions[optionKey];
    }, [studioSelectedProductOptions, designAttributeMappings]);

    useAsyncEffect(() => {
        const setDASearchParamsFromWorkService = async () => {
            const workEntityFromService = (await getWorkEntity(
                auth.getToken(),
                identity,
                workId ?? "",
                fireGenericTrackingEvent
            )) as WorkEntity;
            updateProductAndProjectStateConfiguration({ workId });
            setWorkEntityInSession(workEntityFromService);
            const { queryParams } = workEntityFromService?.design?.metadata || {};
            setDesignAssistantTemplatesSearchParams(queryParams);
        };
        if (isDesignAssistantTabEnabled && workId && isIdentityInitialized) {
            const workEntityFromSession = getWorkEntityFromSession(workId);
            if (workEntityFromSession) {
                const { queryParams } = workEntityFromSession?.design?.metadata || {};
                setDesignAssistantTemplatesSearchParams(queryParams);
            } else setDASearchParamsFromWorkService();
        }
    }, [
        auth,
        identity,
        isDesignAssistantTabEnabled,
        isIdentityInitialized,
        updateProductAndProjectStateConfiguration,
        workId
    ]);

    useEffect(() => {
        if (spinDelay) {
            dispatch(showLoader(t(messages.aiTemplateUpdating.id)));
            return;
        }
        dispatch(hideLoader());
    }, [spinDelay, dispatch, t]);

    return (
        <AiTemplatesLocalizedProvider localizedValues={aiTemplatesLocalizedValues} locale={locale}>
            <AITemplatesProvider
                productKey={productKey}
                productVersion={productVersion || 0}
                selectedOptions={studioSelectedProductOptions}
                updateProductWithNewDocument={updateProductWithNewDocument}
                setAITemplateUpdating={setAITemplateUpdatingState}
                isDesignAssistantTabEnabled={isDesignAssistantTabEnabled}
                designAssistantTemplatesSearchParams={designAssistantTemplatesSearchParams}
                substrateColor={substrateColor}
            >
                {children}
            </AITemplatesProvider>
        </AiTemplatesLocalizedProvider>
    );
});

AITemplatesProviderWrapper.displayName = "AITemplatesProviderWrapper";
