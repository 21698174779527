import { showLoader, useAppDispatch } from "@shared/redux";
import { isCareAgentEditingCareAgentDocument } from "@internal/utils-browser";
import { ERROR_CODES, useErrors } from "@internal/utils-errors";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getAction, fireImpressionsForContexts } from "@internal/utils-siteflow";
import { fireGenericTrackingEvent, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { saveWorkInSession } from "@internal/data-access-work-entity-service";
import { useCartContext } from "@internal/utils-cart";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useCallback } from "react";
// eslint-disable-next-line import/no-restricted-paths -- dependencies must be extracted but beyond current effort
import { useGetDocumentForSave } from "@six/features/Save/useGetDocumentForSave";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { useSave } from "../Save";

const navigationSteps = {
    configureTeamsQuantity: "configureteamsquantity"
};

export const messages = defineMessages({
    navigateToTeamsPage: {
        id: "studio.components.next.navigateToTeamsPage",
        defaultMessage: "Getting your design ready..."
    }
});

export function useNavigateToTeamsPage() {
    const { productKey, productVersion, studioSelectedProductOptions, locale } =
        useProductAndProjectStateManager().data;
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const { t } = useTranslationSSR();
    const save = useSave({
        allowAnonymousUser: true,
        showSaveToast: false,
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave()
    });
    const { handleError, resetErrorState } = useErrors();

    return useCallback(async () => {
        try {
            const startTime = performance.now();

            resetErrorState();
            dispatch(showLoader(t(messages.navigateToTeamsPage.id)));

            const saveResponse = await save();
            if (!saveResponse.success) {
                throw new Error(`Save Failed: ${saveResponse.error.message}`);
            }
            const { work } = saveResponse;
            saveWorkInSession(work);

            if (isCareAgentEditingCareAgentDocument()) {
                // eslint-disable-next-line no-alert
                alert("Document has been saved successfully");
                return;
            }

            newRelicWrapper.addPageAction("studio-exit");
            fireGenericTrackingEvent({
                event: "Studio Continue",
                eventDetail: "Continue Button Clicked",
                label: "Next Button",
                extraData: () => ({
                    workId: work.workId,
                    editFromCartFlow: isItemInCart
                })
            });

            // get next step url to teams page
            const nextStepUrlToTeamsPage = await getAction(
                productKey,
                productVersion!,
                studioSelectedProductOptions,
                locale,
                navigationSteps.configureTeamsQuantity
            );
            if (!nextStepUrlToTeamsPage.url) {
                newRelicWrapper.addPageAction("studio-no-next-step-url", {
                    nextStep: JSON.stringify(nextStepUrlToTeamsPage)
                });
            }
            await fireImpressionsForContexts(nextStepUrlToTeamsPage.context);
            window.location.href = nextStepUrlToTeamsPage?.url?.replace("{workId}", work.workId);

            const endTime = performance.now();
            const timeElapsed = endTime - startTime;
            fireUserInteractionTrackingEvent({
                eventName: "Click Next to Teams Page",
                timing: timeElapsed,
                extraData: {
                    editFromCartFlow: isItemInCart
                }
            });
        } catch (e) {
            newRelicWrapper.addPageAction("studio-fail-add-to-cart", {
                cartError: "message" in e ? e.message : JSON.stringify(e)
            });
            handleError(e, ERROR_CODES.NAVIGATE_TO_TEAMS, ENTITY_CODE);
        }
    }, [
        dispatch,
        isItemInCart,
        locale,
        productKey,
        productVersion,
        save,
        studioSelectedProductOptions,
        t,
        resetErrorState,
        handleError
    ]);
}
