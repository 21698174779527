import React from "react";
import { FlexBox } from "@vp/swan";
import { RedoButton } from "./RedoButton";
import { UndoButton } from "./UndoButton";

import * as styles from "./HeaderHistoryTools.module.scss";

interface HistoryToolsProps {
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    className?: string;
}

export const HistoryTools = ({ undo, redo, canUndo, canRedo, className }: HistoryToolsProps) => {
    return (
        <FlexBox justifyContent="center" alignItems="center" className={className} mx={"3"}>
            <UndoButton onClick={undo} disabled={!canUndo} className={styles.historyButton} />
            <RedoButton onClick={redo} disabled={!canRedo} className={styles.historyButton} />
        </FlexBox>
    );
};

HistoryTools.displayName = "HistoryTools";
