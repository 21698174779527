import React from "react";
import { FlexBox, ToggleSwitch, ToggleSwitchOffLabel, ToggleSwitchOnLabel, Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import * as styles from "./downloadFlowModal.module.scss";

export type ToggleSaveDownloadConfigProps = {
    activated: boolean;
    onChange: (activated: boolean) => void;
};

const messages = defineMessages({
    toggleSaveDownloadSettingsOn: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.toggleSaveDownloadSettingsOn",
        defaultMessage: "Save download settings",
        description: {
            note: "Label for switch when Save download settings is on"
        }
    }
});

export const ToggleSaveDownloadConfig = (props: ToggleSaveDownloadConfigProps) => {
    const { activated, onChange } = props;
    const { t } = useTranslationSSR();

    return (
        <FlexBox
            alignItems="center"
            justifyContent="space-between"
            className={styles.saveDownloadSettingsToggleRow}
            mx={0}
        >
            <Typography fontSize="small" id="toggle-save-download-settings" mr={"4"}>
                {t(messages.toggleSaveDownloadSettingsOn.id)}
            </Typography>
            <ToggleSwitch
                hideText
                aria-labelledby="toggle-save-download-settings"
                activated={activated}
                onRequestActivatedChange={onChange}
                mr={0}
            >
                <ToggleSwitchOnLabel>{t(messages.toggleSaveDownloadSettingsOn.id)}</ToggleSwitchOnLabel>
                <ToggleSwitchOffLabel>{t(messages.toggleSaveDownloadSettingsOn.id)}</ToggleSwitchOffLabel>
            </ToggleSwitch>
        </FlexBox>
    );
};
