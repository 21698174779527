import React, { ReactNode } from "react";
import classNames from "classnames";
import { PanelTitle, PanelContent } from "@internal/ui-studio-chrome";
import * as styles from "./ViewPanelContainer.module.scss";

interface ViewPanelContainerProps {
    className?: string;
    children: ReactNode | ReactNode[];
    title: string;
}

export function ViewPanelContainer({ className, children, title }: ViewPanelContainerProps) {
    return (
        <>
            <PanelTitle>{title}</PanelTitle>
            <PanelContent className={classNames(className, styles.studioGuidesPanelContent)}>{children}</PanelContent>
        </>
    );
}

ViewPanelContainer.displayName = "ViewPanel";
