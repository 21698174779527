import { Item } from "@design-stack-vista/cdif-types";
import { ItemState } from "@design-stack-vista/cimdoc-state-manager";
import {
    BetweenBoundsPayload,
    ImageResolutionPayload,
    OutOfBoundsForPathsPayload,
    OverlapPayload,
    validateImageResolution,
    validateItemBetweenBounds,
    validateItemOutOfBoundsForPaths,
    validateItemsOverlap,
    Validator,
    ValidatorResult
} from "@design-stack-vista/vista-validations";
import { Severity } from "@shared/features/Validations";
// `structuredClone` is not yet supported in Safari 15.0; see https://vistaprint.atlassian.net/wiki/spaces/NTEO/pages/60132385/Vistaprint+browser+support+
// eslint-disable-next-line
import cloneDeep from "lodash/cloneDeep";
import { useTrackingClient, Events } from "@internal/utils-tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { reaction } from "mobx";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useAssetStore, type ImageResolutionValidatorItemConfig } from "@internal/utils-assets";
import {
    BetweenBoundsValidatorItemConfig,
    OutOfBoundsValidatorItemConfig,
    OverlapValidatorItemConfig,
    ValidationName,
    ValidationsConfiguration
} from "../../types";
import { getSeverityFromID } from "../components/helper";
import { getImageResolutionStatus } from "../components/imageResolutionValidation/helper";
import {
    BetweenBoundArgument,
    ImageResolutionArgument,
    ImageResolutionStatus,
    OutOfBoundForPathsArgument,
    OverlapArgument
} from "../components/types";
import {
    createBetweenBoundsValidatorArguments,
    createImageResolutionValidatorArguments,
    createOutOfBoundsForPathsValidatorArguments,
    createOverlapValidatorArguments
} from "./helpers";

export type BoundsValidatorResults =
    | ValidatorResult<BetweenBoundArgument, BetweenBoundsPayload>[]
    | ValidatorResult<OutOfBoundForPathsArgument, OutOfBoundsForPathsPayload>[];

export type BoundsValidatorConfig = OutOfBoundsValidatorItemConfig[] | BetweenBoundsValidatorItemConfig[];

interface ValidationsContextData {
    hasValidationErrorsOnActivePanel: boolean;
    getValidationSeverity: (itemID: string) => Severity | undefined;
    validatorStore: ValidatorStore;
}

type validationName = string;
type itemID = string;
interface ValidationStore {
    [key: validationName]: Map<itemID, Severity>;
}

interface ValidatorStore {
    [key: validationName]: Validator<any, any>;
}

const ValidationsContext = React.createContext<ValidationsContextData>({
    hasValidationErrorsOnActivePanel: false,
    getValidationSeverity: () => undefined,
    validatorStore: {}
});

const validationFunctionMap = {
    [ValidationName.betweenBoundaries]: validateItemBetweenBounds,
    [ValidationName.imageResolution]: validateImageResolution,
    [ValidationName.outsideBounds]: validateItemOutOfBoundsForPaths,
    [ValidationName.outsideMargins]: validateItemOutOfBoundsForPaths,
    [ValidationName.overlap]: validateItemsOverlap
};

const validationArgumentsFunctionMap = {
    [ValidationName.betweenBoundaries]: createBetweenBoundsValidatorArguments,
    [ValidationName.imageResolution]: createImageResolutionValidatorArguments,
    [ValidationName.outsideBounds]: createOutOfBoundsForPathsValidatorArguments,
    [ValidationName.outsideMargins]: createOutOfBoundsForPathsValidatorArguments,
    [ValidationName.overlap]: createOverlapValidatorArguments
};

type TrackedImageValidationEvents = { [id: string]: string[] };

export function useValidations() {
    if (ValidationsContext === undefined) {
        throw new Error("useValidations must be used within a ValidationStoreProvider");
    }
    return useContext(ValidationsContext);
}

export function ValidationStoreProvider({
    children,
    config
}: {
    children: ReactNode | ReactNode[];
    config: ValidationsConfiguration;
}) {
    const designEngine = useAvailableDesignEngine();
    const trackingClient = useTrackingClient();
    const [validationStore, setValidationStore] = useState<ValidationStore>({});
    const [validatorStore, setValidatorStore] = useState<ValidatorStore>({});
    const [, setTrackedItems] = useState<TrackedImageValidationEvents>({});

    const assetStore = useAssetStore();

    useEffect(() => {
        let validators: any[] = [];
        if (designEngine) {
            validators = Object.entries(config)
                .filter(([, validationConfig]) => validationConfig.enabled)
                .map(([validationName, validationConfig]) => {
                    const validator = new Validator(
                        // @ts-ignore FIXME: must handle implicit `any` type
                        validationFunctionMap[validationName],
                        () =>
                            // @ts-ignore FIXME: must handle implicit `any` type
                            validationArgumentsFunctionMap[validationName]({
                                config: validationConfig.config,
                                designEngine,
                                assetStore: validationName === ValidationName.imageResolution ? assetStore : undefined
                            }),
                        { throttleTime: Math.random() * 300 + 100 }
                    );
                    setValidatorStore(currentStore => {
                        return { ...currentStore, [validationName]: validator };
                    });
                    return validator;
                });
        }

        return () => {
            validators.forEach(validator => validator.dispose());
        };
    }, [designEngine, config, assetStore]);

    const updateBoundsValidations = useCallback(
        (
            validationName: string,
            results: BoundsValidatorResults,
            config: BoundsValidatorConfig,
            items: ItemState<Item>[] = []
        ) => {
            setValidationStore(currentStore => {
                const nextValidationStore = cloneDeep(currentStore);
                const validation = new Map<string, Severity>();
                results.forEach(({ result, source }) => {
                    !result?.isValid
                        ? validation.set(source.id, getSeverityFromID(source.id, items, config)!)
                        : validation.delete(source.id);
                });
                nextValidationStore[validationName] = validation;
                return nextValidationStore;
            });
        },
        []
    );

    const trackValidationEventOnce = useCallback(
        (id: string, validation: ValidationName, eventDetail: string, label: string = "General") => {
            setTrackedItems(previouslyTrackedItems => {
                const existingTrackedItem = previouslyTrackedItems[id] ?? [];
                if (existingTrackedItem.includes(`${validation}-${eventDetail}`)) {
                    // Early return if the validation event has already been tracked
                    return previouslyTrackedItems;
                }
                trackingClient.track(Events.StudioValidationShown, {
                    eventDetail,
                    label
                });
                const updatedTrackedItems = previouslyTrackedItems;
                updatedTrackedItems[id] = [...existingTrackedItem, `${validation}-${eventDetail}`];
                return updatedTrackedItems;
            });
        },
        [trackingClient]
    );

    const updateImageResolutionValidations = useCallback(
        (
            results: ValidatorResult<ImageResolutionArgument, ImageResolutionPayload>[],
            config: ImageResolutionValidatorItemConfig
        ) => {
            setValidationStore(currentStore => {
                const nextValidationStore = cloneDeep(currentStore);
                const validation = new Map<string, Severity>();
                results.forEach(({ result, source }) => {
                    if (!result?.payload) return;
                    const imageResolutionStatus = getImageResolutionStatus(result!.payload!.ppi, config);
                    if (imageResolutionStatus === ImageResolutionStatus.OK) {
                        validation.delete(source.id);
                        return;
                    }
                    const isError = imageResolutionStatus === ImageResolutionStatus.ERROR;
                    const isWarning = imageResolutionStatus === ImageResolutionStatus.WARNING;

                    if (isError || isWarning) {
                        trackValidationEventOnce(
                            source.id,
                            ValidationName.imageResolution,
                            isError
                                ? STUDIO_TRACKING_EVENTS.LOW_RESOLUTION_IMAGE_ERROR
                                : STUDIO_TRACKING_EVENTS.LOW_RESOLUTION_IMAGE_WARNING
                        );
                    }

                    validation.set(source.id, isError ? Severity.ERROR : Severity.WARNING);
                });

                nextValidationStore.imageResolution = validation;
                return nextValidationStore;
            });
        },
        [trackValidationEventOnce]
    );

    const updateOverlapValidations = useCallback(
        (
            results: ValidatorResult<OverlapArgument, OverlapPayload>[],
            config: OverlapValidatorItemConfig[],
            items: ItemState<Item>[] = []
        ) => {
            setValidationStore(currentStore => {
                const nextValidationStore = cloneDeep(currentStore);
                const validation = new Map<string, Severity>();
                results.forEach(({ result, source }) => {
                    if (!result?.payload) return;
                    source.items.forEach(({ id }) =>
                        result!.payload?.overlapRegions.forEach(({ itemIds }) => {
                            itemIds.includes(id)
                                ? validation.set(id, getSeverityFromID(id, items, config)!)
                                : validation.delete(id);
                        })
                    );
                });

                nextValidationStore.overlap = validation;
                return nextValidationStore;
            });
        },
        []
    );

    useEffect(() => {
        const validationName = ValidationName.outsideMargins;
        const dispose = reaction(
            () => validatorStore[validationName]?.results,
            results => {
                if (!designEngine) return;
                const items = designEngine.cimDocStore.panels
                    .flatMap(panel => panel.items)
                    .filter((item): item is ItemState<Item> => !item.isSubpanelState());
                updateBoundsValidations(validationName, results, config[validationName].config, items);
            }
        );

        return dispose;
    }, [config, designEngine, updateBoundsValidations, validatorStore]);

    useEffect(() => {
        const validationName = ValidationName.outsideBounds;
        const dispose = reaction(
            () => validatorStore[validationName]?.results,
            results => {
                if (!designEngine) return;
                const items = designEngine.cimDocStore.panels
                    .flatMap(panel => panel.items)
                    .filter((item): item is ItemState<Item> => !item.isSubpanelState());
                updateBoundsValidations(validationName, results, config[validationName].config, items);
            }
        );

        return dispose;
    }, [config, designEngine, updateBoundsValidations, validatorStore]);

    useEffect(() => {
        const validationName = ValidationName.betweenBoundaries;
        const dispose = reaction(
            () => validatorStore[validationName]?.results,
            results => {
                if (!designEngine) return;
                const items = designEngine.cimDocStore.panels
                    .flatMap(panel => panel.items)
                    .filter((item): item is ItemState<Item> => !item.isSubpanelState());
                updateBoundsValidations(validationName, results, config[validationName].config, items);
            }
        );

        return dispose;
    }, [config, designEngine, updateBoundsValidations, validatorStore]);

    useEffect(() => {
        const validationName = ValidationName.imageResolution;
        const dispose = reaction(
            () => validatorStore[validationName]?.results,
            results => {
                updateImageResolutionValidations(results, config[validationName].config);
            }
        );

        return dispose;
    }, [config, designEngine, updateImageResolutionValidations, validatorStore]);

    useEffect(() => {
        const validationNames = Object.keys(ValidationName);

        const disposableFunctions = validationNames.map((validationName: ValidationName) => {
            return reaction(
                () => validatorStore[validationName]?.results,
                results => {
                    results.forEach(({ result, source }) => {
                        if (result?.payload && !result?.isValid) {
                            trackValidationEventOnce(source.id, validationName, validationName, "User Validation");
                        }
                    });
                }
            );
        });

        return () => {
            disposableFunctions.forEach(dispose => {
                dispose();
            });
        };
    }, [config, designEngine, trackValidationEventOnce, validatorStore]);

    useEffect(() => {
        const validationName = ValidationName.overlap;
        const dispose = reaction(
            () => validatorStore[validationName]?.results,
            results => {
                if (!designEngine) return;
                const items = designEngine.cimDocStore.panels
                    .flatMap(panel => panel.items)
                    .filter((item): item is ItemState<Item> => !item.isSubpanelState());
                updateOverlapValidations(results, config[validationName].config, items);
            }
        );

        return dispose;
    }, [config, designEngine, updateOverlapValidations, validatorStore]);

    const hasValidationErrorsOnActivePanel = useMemo(() => {
        const activePanel = designEngine?.cimDocStore.panels.find(
            panel => panel.id === designEngine?.idaStore.activeDesignPanelId
        );
        if (!activePanel) {
            return false;
        }
        const itemIdsOnActivePanel = activePanel?.items.map(item => item.id);

        return Object.values(validationStore).some(validation =>
            [...validation.keys()].some(
                itemId => itemIdsOnActivePanel.includes(itemId) && validation.get(itemId) === Severity.ERROR
            )
        );
    }, [validationStore, designEngine?.cimDocStore.panels, designEngine?.idaStore.activeDesignPanelId]);

    const getValidationSeverity = useCallback(
        (itemID: string) => {
            return Object.values(validationStore).reduce((severity, currentValidation) => {
                if (severity === Severity.ERROR) return severity;
                const currentSeverity = currentValidation.get(itemID);
                return currentSeverity || severity;
            }, undefined);
        },
        [validationStore]
    );

    const contextObject = useMemo(
        () => ({
            hasValidationErrorsOnActivePanel,
            getValidationSeverity,
            validatorStore
        }),
        [hasValidationErrorsOnActivePanel, getValidationSeverity, validatorStore]
    );

    return <ValidationsContext.Provider value={contextObject}>{children}</ValidationsContext.Provider>;
}

ValidationStoreProvider.displayName = "ValidationStoreProvider";
