import { DownloadFormats, RESOLUTIONS, Resolution } from "./DownloadFlow/types";
import { getScale } from "./DownloadFlow/utils";

export type RenderingUrlParams = {
    width?: number;
    height?: number;
    resolution?: Resolution;
    instructionsUri: string;
    scene?: string;
    format?: DownloadFormats;
    quality?: number;
    isTransparent?: boolean;
};

export const buildRenderingUrl = (params: RenderingUrlParams): string => {
    const {
        isTransparent,
        width,
        height,
        instructionsUri,
        scene,
        format = "jpg",
        resolution = RESOLUTIONS.standard,
        quality = 100
    } = params;
    const returnUrl = new URL(`${RENDERING_SERVICE_URL}/v2/vp/preview`);

    if (width) {
        returnUrl.searchParams.set("width", (width * getScale(resolution)).toString());
    }

    if (height) {
        returnUrl.searchParams.set("height", (height * getScale(resolution)).toString());
    }

    if (instructionsUri) {
        returnUrl.searchParams.set("instructions_uri", instructionsUri);
    }

    if (scene) {
        returnUrl.searchParams.set("scene", scene);
    }

    if (format) {
        returnUrl.searchParams.set("format", format);
    }

    if (quality && format === "jpg") {
        returnUrl.searchParams.set("quality", quality.toString());
    }

    if (isTransparent === false && format === "png") {
        returnUrl.searchParams.set("bgcolor", "ffffff");
    }

    return returnUrl.toString();
};
