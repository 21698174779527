import React from "react";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useActiveDialog, DialogCoreValues } from "@internal/utils-active-dialog";
import { PlusIcon } from "@internal/ui-studio-chrome";
import { useDesignEngine, useSelectedItems } from "@design-stack-vista/core-features";
import * as styles from "./FloatingAddContentButton.module.scss";

const messages = defineMessages({
    addButton: {
        id: "studio.buttons.addButton",
        defaultMessage: "Add an element",
        description: {
            note: "alt text for a + button that allows the user to add elements to their design"
        }
    }
});

export const FloatingAddContentButton = () => {
    const { t } = useTranslationSSR();
    const designEngine = useDesignEngine();
    const { currentActiveDialog, addButtonOffset: bottom } = useActiveDialog();
    const { trackEvent } = useTrackEvents();
    const { selectedItemIds } = useSelectedItems();
    const hideAddButton = selectedItemIds.length === 0 || currentActiveDialog !== DialogCoreValues.None;
    const onClick = () => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ADD_BUTTON });
        designEngine.idaStore.setSelectedIds([]);
    };

    return (
        <button
            className={classNames(styles.floatingAddButton, {
                [styles.floatingAddButtonHidden]: hideAddButton
            })}
            style={{ bottom: `${bottom}px` }}
            onClick={onClick}
            aria-label={t(messages.addButton.id)}
            data-testid="add-content-button"
        >
            <PlusIcon />
        </button>
    );
};

FloatingAddContentButton.displayName = "FloatingAddContentButton";
