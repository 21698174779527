import { defineMessages } from "@vp/i18n-helper";

export const mailingServicesMessages = defineMessages({
    guideDialogAriaLabel: {
        id: "studio.components.mailingServices.guideDialogAriaLabel",
        defaultMessage: "Mailing services guide modal dialog",
        description: {
            note: "Accessible text for mailing services guide modal"
        }
    },
    closeModalAriaLabel: {
        id: "studio.components.mailingServices.closeModalAriaLabel",
        defaultMessage: "Close",
        description: {
            note: "mailing services dialog close button aria label"
        }
    },
    mailingConfirmationModalHeader: {
        id: "studio.components.mailingServices.mailingConfirmationModalHeader",
        defaultMessage: "Great, we'll mail these out for you!",
        description: {
            note: "appears as header text on the confirmation modal when user click Yes, mail them for me button"
        }
    },
    mailingGuideTitle: {
        id: "studio.components.mailingServices.mailingGuideTitle",
        defaultMessage: "Make sure the back prints properly",
        description: {
            note: "Appears on guide modal as a title when user click on Yes, mail them for me button"
        }
    },
    mailingGuideBodyInformation: {
        id: "studio.components.mailingServices.mailingGuideBodyInformation",
        defaultMessage:
            // eslint-disable-next-line max-len
            "The back of your postcard has an area reserved for the mailing address. To make sure all your design elements on the back print properly, keep them out of the mailing address area.",
        description: {
            note: "Appears on guide modal as a description when user click on Yes, mail them for me button"
        }
    },
    mailingGuideButtonTitle: {
        id: "studio.components.mailingServices.mailingGuideButtonTitle",
        defaultMessage: "Continue",
        description: {
            note: "Appears on guide modal, continue button will close the guide modal"
        }
    },
    mailingButton: {
        id: "studio.components.MailingServices.mailingButton",
        defaultMessage: "Mailing services"
    },
    mailingServicesModalHeader: {
        id: "studio.components.mailingServices.mailingServicesModalHeader",
        defaultMessage: "Mailing these? We’ll do it for you.",
        description: {
            note: "Text for mailing services modal header"
        }
    },
    mailingPostcardPrizingTag: {
        id: "studio.components.mailingServices.mailingPostcardPrizingTag",
        defaultMessage:
            "See how much time and money you’ll save with our postcard mailing services. Get a quote. Questions? Call 1-855-210-1085. ",
        description: {
            note: "Text for mailing services postcard pricing tag"
        }
    },
    checkListItem_1: {
        id: "studio.components.mailingServices.checkList1",
        defaultMessage: "Save an average of 3 hours on addressing and stamping each postcard"
    },
    checkListItem_2: {
        id: "studio.components.mailingServices.checkList2",
        defaultMessage: "Save up to 10%"
    },
    checkListItem_3: {
        id: "studio.components.mailingServices.checkList3",
        defaultMessage: " on USPS postage rates"
    },
    checkListItem_4: {
        id: "studio.components.mailingServices.checkList4",
        defaultMessage: "Guaranteed delivery"
    },
    mailingOptions: {
        id: "studio.components.mailingServices.mailingOptions",
        defaultMessage: "Yes, mail them for me",
        description: {
            note: "Text for Yes, mail them for me button in mailing services options modal"
        }
    },
    mailingButtonBelowText: {
        id: "studio.components.mailingServices.mailingButtonBelowText",
        defaultMessage: " You'll set up your mailing list later.",
        description: {
            note: "Text appears below the mailing options button in mailing services options modal"
        }
    },
    nonMailingOption: {
        id: "studio.components.mailingServices.nonMailingOption",
        defaultMessage: "No, thanks",
        description: {
            note: "Text for no thanks button in mailing services options modal"
        }
    },
    close: {
        id: "studio.components.mailingServices.close",
        defaultMessage: "Close",
        description: {
            note: "Accessible text for close button in mailing services options modal"
        }
    },
    faqDialogAriaLabel: {
        id: "studio.components.mailingServices.faqDialogAriaLabel",
        defaultMessage: "Mailing services FAQ modal dialog",
        description: {
            note: "Accessible text for mailing services FAQ modal"
        }
    },
    optionModalAriaLabel: {
        id: "studio.components.mailingServices.optionModalAriaLabel",
        defaultMessage: "Mailing services option modal dialog",
        description: {
            note: "Accessible text for mailing services option modal"
        }
    },
    cancel: {
        id: "studio.components.mailingServices.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Accessible text for Remove mailing services button in mailing services FAQ modal"
        }
    },
    mailingServiceFaqTitleOne: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqTitleOne",
        defaultMessage: "You've added mailing services.",
        description: {
            note: `Text for mailing services FAQ modal to display title you've added mailing services. `
        }
    },
    mailingServiceFaqTitleTwo: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqTitleTwo",
        defaultMessage: "Frequently Asked Questions:",
        description: {
            note: `Text for mailing services FAQ modal to display title frequently asked questions. `
        }
    },
    mailingServiceFaqHeaderOne: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqHeaderOne",
        defaultMessage: "What are the advantages of using your postcard mailing services?",
        description: {
            note: `Text for mailing services FAQ modal to display first header which tells the advantages of using a mailing service postcard.`
        }
    },
    mailingServiceFaqContentOne: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqContentOne",
        defaultMessage: `Our postcard mailing services make it easy for you to design, print and send postcard mailers – all in one stress-free experience.
        Plus, list processing, address validation, address printing and delivery to the post office are also included in the postcard mailing fees.`,
        description: {
            note: `Text for mailing services FAQ modal to display first content which tells the flow in which mailing service works.`
        }
    },
    mailingServiceFaqHeaderTwo: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqHeaderTwo",
        defaultMessage: "What file formats are acceptable to use for my mailing list?",
        description: {
            note: `Text for mailing services FAQ modal to display third header which tells the file formats are acceptable for mailing service postcard.`
        }
    },
    mailingServiceFaqContentTwo: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqContentTwo",
        defaultMessage: `We recommend using our downloadable Excel mailing list template to ensure you have the proper list format.
        We also accept Comma Separated Value (.CSV) and Tab Delimited (.txt) files as long as you include the column headers that match our template.
        `,
        description: {
            note: `Text for mailing services FAQ modal to display second content which tells the acceptable formats for mailing services postcard`
        }
    },
    mailingServiceFaqHeaderThree: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqHeaderThree",
        defaultMessage: "Will my address list be saved on my account?",
        description: {
            note: `Text for mailing services FAQ modal to display third header which tells whether address list
            will be saved on customers account.`
        }
    },
    mailingServiceFaqContentThree: {
        id: "studio.components.mailingServicesFAQ.mailingServiceFaqContentThree",
        defaultMessage: `Yes, once you upload your address list, it will be saved on your account and you’ll be able to reuse it for future mailings.`,
        description: {
            note: `Text for mailing services FAQ modal to display third content which tells the how your address list gets saved for mailing services postcard`
        }
    },
    removeMailingServices: {
        id: "studio.components.mailingServicesFAQ.removeMailingServices",
        defaultMessage: "Remove mailing services",
        description: {
            note: "Accessible text for Remove mailing services button in mailing services FAQ modal"
        }
    },
    behindUSPSAreaItemsRelocatingText: {
        id: "studio.components.mailingServices.behindUSPSAreaItemsRelocatingText",
        defaultMessage:
            "There are objects covered by the mailing address that won’t appear when your design is printed.",
        description: {
            note: `appears in the alert at the bottom of the canvas when a mailing postcard is selected
                 for behind USPS area items`
        }
    },
    bringIntoViewButtonText: {
        id: "studio.components.mailingServices.bringIntoViewButtonText",
        defaultMessage: "Move objects into view.",
        description: {
            note: `'Move objects into view' link that appears in the alert at the bottom of the canvas when
            a mailing postcard is selected`
        }
    },
    confirmationDialogAriaLabel: {
        id: "studio.components.mailingServices.confirmationDialogAriaLabel",
        defaultMessage: "Mailing services confirmation modal dialog",
        description: {
            note: "Accessible text for mailing services confirmation modal"
        }
    },
    mailingConfirmationModalMessage: {
        id: "studio.components.mailingServices.mailingConfirmationModalMessage",
        defaultMessage: `Mailing services have been added. The mailing address of each recipient is printed on the back of each postcard.
            You'll add your mailing list before checkout.`,
        description: {
            note: "appears on the confirmation modal when user click Yes, mail them for me button"
        }
    },
    continue: {
        id: "studio.components.mailingServices.continue",
        defaultMessage: "Continue",
        description: {
            note: "continue button text for mailing services confirmation modal"
        }
    }
});
