import React, { useRef, useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { SheetPanelWrapper, FileMenuDialogTypes } from "@shared/features/GlobalHeader";
import { useActiveDialog, useActiveDialogSetters, DialogCoreValues } from "@internal/utils-active-dialog";
import { Sheet, useClickOutside } from "@internal/ui-studio-chrome";
import { ViewPanelContent } from "./ViewPanelContent";
import { ViewSheetDialogTypes } from "./ViewSheetDialogTypes";
import * as styles from "./ViewSheet.module.scss";

const messages = defineMessages({
    viewPanel: {
        id: "studio.ui.component.guidesPanel.viewPanel",
        defaultMessage: "View",
        description: {
            note: "Title for the view panel"
        }
    },
    viewSheet: {
        id: "studio.ui.component.guidesPanel.viewSheet",
        defaultMessage: "View Sheet"
    }
});

export function ViewSheet() {
    const { t } = useTranslationSSR();
    const [sheetHeight, setSheetHeight] = useState(0);
    const sheetRef = useRef<HTMLDivElement>(null);
    const { currentActiveDialog } = useActiveDialog();
    const { setCurrentActiveDialog } = useActiveDialogSetters();

    useClickOutside(
        sheetRef,
        async () => {
            if (sheetHeight) {
                setCurrentActiveDialog(DialogCoreValues.None);
            }
        },
        [sheetHeight]
    );

    const reportSizeChanged = (rect: DOMRect) => {
        if (rect) {
            setSheetHeight(rect.height);
        }
    };

    return (
        <div ref={sheetRef}>
            <Sheet
                ariaLabel={t(messages.viewSheet.id)}
                limitHeight={false}
                reportSizeChange={reportSizeChanged}
                hideSheet={currentActiveDialog !== ViewSheetDialogTypes.guides}
            >
                <SheetPanelWrapper
                    backContentType={FileMenuDialogTypes.file}
                    contentType={ViewSheetDialogTypes.guides}
                    sheetTitle={t(messages.viewPanel.id)}
                >
                    <ViewPanelContent className={styles.viewSheet} />
                </SheetPanelWrapper>
            </Sheet>
        </div>
    );
}
