import { CimDoc } from "@design-stack-vista/cdif-types";
import { validateBlankDocument } from "@design-stack-vista/vista-validations";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getPanelIndex } from "@shared/features/DesignPanel/utils";
import { useCallback } from "react";

export enum BlankDocumentValidationPanel {
    Back = "back"
}

/**
 * Determines whether a panel is designable based on its color mode.
 *
 * A panel is considered designable if its `colorMode` is either `"color"` or `undefined`.
 *
 * @param panelColorMode - The color mode of the panel.
 * @returns `true` if the panel is designable, `false` otherwise.
 */
function isPanelDesignable(panelColorMode?: string): boolean {
    /**
     * If colorMode is `undefined` or `color`, we consider the back panel as designable.
     * https://vistaprint.slack.com/archives/C02D88QQYRM/p1658856618258499?thread_ts=1658850366.948119&cid=C02D88QQYRM
     */
    if (panelColorMode === "color" || panelColorMode === undefined) {
        return true;
    }
    return false;
}

/**
 * Provides utilities for validating whether a CimDoc has any blank and designable panels.
 *
 * The `useBlankDocumentValidation` hook returns two functions:
 * - `checkForBlankDocument`: Checks if all panels in the given CimDoc are blank.
 * - `checkForBlankPanel`: Checks if a specific panel in the given CimDoc is blank and designable.
 *
 */
export function useBlankDocumentValidation() {
    /**
     * Validates the given CimDoc for blank document.
     *
     * @param cimDoc - The CimDoc to validate for blank document.
     * @returns `ValidationResult<BlankDocumentPayload>`
     */
    const validateForBlankDocument = useCallback(async (cimDoc: CimDoc) => {
        const result = await validateBlankDocument({ cimDoc });
        return result;
    }, []);

    /**
     * Checks if a panel in the given CimDoc is designable and doesn't contain any design items.
     *
     * @param cimDoc - The CimDoc to check.
     * @param panelName - The name of the panel to check.
     * @returns `true` if the panel is blank and designable, `false` otherwise.
     * Logs Error if there is an error validating the document and return `false`.
     */
    const isPanelBlank = useCallback(
        async (cimDoc: CimDoc, panelName: string) => {
            try {
                const validationResult = await validateForBlankDocument(cimDoc);
                const backIndex = getPanelIndex(cimDoc, panelName);
                if (backIndex === -1) {
                    // We do not have a back panel in the document.
                    return false;
                }
                const isBackPanelBlankInDocument = validationResult.payload?.panels[backIndex].isValid === false;

                const isBackPanelDesignable = isPanelDesignable(cimDoc.document.panels[backIndex].colorMode);

                // We consider back-panel for blank-document validation, if it is designable and does not contain any design
                const isBackPanelBlank = isBackPanelBlankInDocument && isBackPanelDesignable;

                return isBackPanelBlank;
            } catch (error) {
                newRelicWrapper.noticeError(new Error("Error in validating blank document", error));
            }
            return false;
        },
        [validateForBlankDocument]
    );

    /**
     * Checks if all panels in the given CimDoc are blank.
     *
     * @param cimDoc - The CimDoc to check.
     * @returns `true` if all panels are blank, `false` otherwise.
     * Logs an error if there is an error validating the document and returns `false`.
     */
    const isDocumentBlank = useCallback(
        async (cimDoc: CimDoc): Promise<boolean> => {
            try {
                const validationResult = await validateForBlankDocument(cimDoc);
                return !validationResult.isValid;
            } catch (err) {
                newRelicWrapper.noticeError(new Error("Error in validating blank document", err));
                return false;
            }
        },
        [validateForBlankDocument]
    );

    return { isDocumentBlank, isPanelBlank };
}
