import React, { useMemo } from "react";
import type { Guide, Mask, MaskType } from "@design-stack-vista/ida-framework";
import { DesignLayer } from "@design-stack-vista/ida-framework";
import { observer } from "mobx-react-lite";
import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import {
    PanelLayoutExtension,
    useDesignEngine,
    PanelChromesExtension,
    getOptionalExtension,
    useActivePanel,
    useRequiredExtension
} from "@design-stack-vista/core-features";
import { useAppSelector } from "@shared/redux";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { tokens } from "@vp/swan";
import { getSurfaceRectangleWithMask } from "@six/features/editorUI/validations/components/helper";
import { useStudioConfigurationManager } from "@internal/dex";
import { ViewPanelExtension } from "@six/features/editorUI/viewPanel/extensions";
import { useShowPanelSections } from "@shared/features/PanelSections";
import { getLabelPositionXYCoordinates, isNonRectangularPath } from "./helpers";
import type { AreaLabelProps } from "./DesignAreaLabel";
import { DesignAreaLabel } from "./DesignAreaLabel";
import * as styles from "./DesignAreaLabelsLayer.module.scss";

const strokeColors = {
    BLEED: { maskColor: tokens.SwanBaseColorBlue700 },
    FOLD: { maskColor: "#FF0000" },
    CUT: { maskColor: "#0000FF" },
    TRIM: { maskColor: "#C4CDD6" },
    SAFE: { maskColor: "#0a856a" }
};

enum TYPES {
    BLEED = "bleed",
    FOLD = "fold",
    CUT = "cut",
    SAFE = "safe"
}
export interface DesignAreaLabelsLayerProps {
    panel: PanelState;
    showSizeIndicator?: boolean;
    onLabelHover?: (highlightedLabelType?: MaskType) => void;
}

export type ChromeLabel = AreaLabelProps & { maskType: MaskType };

const messages = defineMessages({
    bleedLabel: {
        id: "editorUI.panelDecorations.panelLabels.bleed.label",
        defaultMessage: "Bleed"
    },
    safeLabel: {
        id: "editorUI.panelDecorations.panelLabels.safe.label",
        defaultMessage: "Safety Area"
    },
    trimLabel: {
        id: "editorUI.panelDecorations.panelLabels.trim.label",
        defaultMessage: "Trim Line"
    },
    foldLabel: {
        id: "editorUI.panelDecorations.panelLabels.fold.label",
        defaultMessage: "Fold Line"
    },
    cutLabel: {
        id: "editorUI.panelDecorations.panelLabels.cut.label",
        defaultMessage: "Cut Line"
    },
    bleedTooltip: {
        id: "editorUI.panelDecorations.panelLabels.bleed.tooltip",
        defaultMessage: "Your background should reach this line - it prevents white lines along the edges."
    },
    safeTooltip: {
        id: "editorUI.panelDecorations.panelLabels.safe.tooltip",
        defaultMessage: "Any text and images need to fit within this box."
    },
    trimTooltip: {
        id: "editorUI.panelDecorations.panelLabels.trim.tooltip",
        defaultMessage: "Anything outside this line might be cut off"
    },
    foldTooltip: {
        id: "editorUI.panelDecorations.panelLabels.fold.tooltip",
        defaultMessage: "The product folds along this line"
    },
    cutTooltip: {
        id: "editorUI.panelDecorations.panelLabels.cut.tooltip",
        defaultMessage: "The product will be cut at this line"
    }
});

export function getMasksAndGuidesToLabel(masks: Mask[], guides: Guide[]) {
    // @ts-ignore FIXME: must handle implicit `any` type
    let availableMasksAndGuides = [...masks, ...guides].filter(({ type, paths }) => TYPES[type] && paths.length);

    // If there are multiple bleeds, label the one that is visually highest on the screen
    let lowestBleedY = Infinity;
    let bleedToUse = masks.find(mask => mask.type === "BLEED");
    availableMasksAndGuides
        .filter(mask => mask.type === "BLEED")
        .forEach((mask, index) => {
            mask.paths.forEach(path => {
                path.points.forEach(point => {
                    if (point.y < lowestBleedY) {
                        lowestBleedY = point.y;
                        bleedToUse = mask;
                    }
                });
            });
        });

    // If there are multiple safety areas, label the one that is visually highest on the screen
    let lowestSafeY = Infinity;
    let safeToUse = masks.find(mask => mask.type === "SAFE");
    availableMasksAndGuides
        .filter(mask => mask.type === "SAFE")
        .forEach((mask, index) => {
            mask.paths.forEach(path => {
                path.points.forEach(point => {
                    if (point.y < lowestSafeY) {
                        lowestSafeY = point.y;
                        safeToUse = mask;
                    }
                });
            });
        });

    availableMasksAndGuides = availableMasksAndGuides.filter(mask => mask.type !== "BLEED" && mask.type !== "SAFE");
    if (bleedToUse) {
        availableMasksAndGuides.push(bleedToUse);
    }
    if (safeToUse) {
        availableMasksAndGuides.push(safeToUse);
    }

    return availableMasksAndGuides;
}

export const LabelOffsetFromTopOfAreaBeingLabeled = 18;

export const DesignAreaLabelsLayer = observer(
    ({ panel, showSizeIndicator = true, onLabelHover = () => {} }: DesignAreaLabelsLayerProps) => {
        const { t } = useTranslationSSR();
        const designRequirements = useDesignRequirementsContext();
        const { masks, guides } = useRequiredExtension(panel, PanelChromesExtension);
        const { isSmall } = useStudioLayout();
        const showBleed = useAppSelector((state: { showBleed: boolean }) => state.showBleed);
        const { shouldUseTrimPaths: showTrim } = useStudioConfigurationManager().data;
        const showSafetyArea = useAppSelector((state: { showSafetyArea: boolean }) => state.showSafetyArea);
        const designEngine = useDesignEngine();
        const shouldShowPanelSections = useShowPanelSections();
        const { activePanel } = useActivePanel();

        const viewPanelExtension = activePanel && getOptionalExtension(designEngine, activePanel, ViewPanelExtension);

        const totalWidth =
            useRequiredExtension(panel, PanelLayoutExtension).dimensions.width / designEngine.layoutStore.zoom;

        const shouldShow = {
            TRIM: shouldShowPanelSections ? viewPanelExtension?.isEnabled("TRIM") : showTrim,
            BLEED: shouldShowPanelSections ? viewPanelExtension?.isEnabled("BLEED") : showBleed,
            SAFE: shouldShowPanelSections ? viewPanelExtension?.isEnabled("SAFE") : showSafetyArea
        };

        const chromeLabels: ChromeLabel[] = useMemo(() => {
            if (isSmall) {
                return [];
            }

            const availableMasksAndGuides = getMasksAndGuidesToLabel(masks, guides);

            const labels = availableMasksAndGuides.map(mask => {
                // These are hardcoded to match Studio5's label positioning for the 2023 A/B test between Studio5 and Studio6.
                // If you're not running the 2023 A/B test between Studio5 and Studio6, feel free to change this however you want, we wanted to
                // match the Studio5 behavior for the test to eliminate variables between the two experiences
                let offsetXPercentage = 0;
                switch (mask.type) {
                    case "BLEED":
                        offsetXPercentage = 47;
                        break;
                    case "SAFE":
                        offsetXPercentage = 15;
                        break;
                    case "TRIM":
                        offsetXPercentage = 75;
                        break;
                    default:
                        offsetXPercentage = 0;
                }
                let xPositionInSvgSpaceCoordinates = (offsetXPercentage / 100) * totalWidth;

                // Start with the naive rectangle bounds of the mask
                const maskBoundingArea = getSurfaceRectangleWithMask(
                    panel,
                    mask,
                    designEngine.layoutStore.zoom,
                    designEngine
                );
                let yPosition = maskBoundingArea.y * designEngine.layoutStore.zoom;

                // if the mask isn't a rectangle, be smarter about figuring out its bounds
                if (mask.paths.some(path => isNonRectangularPath(path))) {
                    const newPositions = getLabelPositionXYCoordinates(
                        xPositionInSvgSpaceCoordinates,
                        mask.paths,
                        yPosition
                    );
                    yPosition = newPositions.y * designEngine.layoutStore.zoom;
                    xPositionInSvgSpaceCoordinates = newPositions.x;
                }

                return {
                    maskType: mask.type,
                    // @ts-ignore FIXME: must handle implicit `any` type
                    label: t(messages[`${mask.type.toLowerCase()}Label`].id),
                    // @ts-ignore FIXME: must handle implicit `any` type
                    tooltip: t(messages[`${mask.type.toLowerCase()}Tooltip`].id),
                    backgroundColor: "#FFFFFF",
                    // @ts-ignore FIXME: must handle implicit `any` type
                    maskColor: strokeColors[mask.type].maskColor,
                    offsetYPixels: yPosition - LabelOffsetFromTopOfAreaBeingLabeled,
                    offsetXPixels: xPositionInSvgSpaceCoordinates * designEngine.layoutStore.zoom
                };
            });
            // @ts-ignore FIXME: must handle implicit `any` type
            return labels.filter(({ label, maskType }) => label && shouldShow[maskType]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isSmall, masks, guides, shouldShow]);

        return (
            <DesignLayer name="pde-labels-layer">
                <div className={styles.labelsContainer}>
                    <ChromeDesignAreaLabels
                        chromeLabels={chromeLabels}
                        showSizeIndicator={showSizeIndicator}
                        onLabelHover={onLabelHover}
                    />

                    <DesignAreaLabel
                        // Use localized panel name if it is available
                        label={designRequirements?.getPanelById(panel.id)?.locationName ?? panel.panelProperties.name}
                        backgroundColor={""}
                    />
                </div>
            </DesignLayer>
        );
    }
);

interface ChromeDesignAreaLabelsProps {
    chromeLabels: ChromeLabel[];
    showSizeIndicator: boolean;
    onLabelHover: (highlightedLabelType?: MaskType) => void;
}

export const ChromeDesignAreaLabels = ({
    chromeLabels,
    showSizeIndicator,
    onLabelHover
}: ChromeDesignAreaLabelsProps) => {
    if (!showSizeIndicator && !chromeLabels.length) return null;

    return (
        <>
            {chromeLabels.map(
                ({ label, tooltip, backgroundColor, maskColor, maskType, offsetYPixels, offsetXPixels }, index) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                            key={`${label}-${index}`}
                            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                            onMouseOver={() => onLabelHover(maskType)}
                            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                            onMouseOut={() => onLabelHover(undefined)}
                        >
                            <DesignAreaLabel
                                label={label}
                                backgroundColor={backgroundColor}
                                maskColor={maskColor}
                                tooltip={tooltip}
                                showIndicatorLine={true}
                                offsetXPixels={offsetXPixels}
                                offsetYPixels={offsetYPixels}
                            />
                        </div>
                    );
                }
            )}
        </>
    );
};
