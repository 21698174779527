import React, { useCallback } from "react";
import { MailingIcon, MailingServiceActiveIcon, GenericHeaderButton } from "@internal/ui-studio-chrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useCartContext } from "@internal/utils-cart";
import {
    useMailingServicesContext,
    useIsMailingServicesEnabled,
    useIsMailingServicesCompatibleProduct
} from "@shared/features/MailingServices";
import { observer } from "mobx-react-lite";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { mailingServicesMessages } from "./messages";

interface Props {
    className?: string;
}

export const MailingButton = observer(({ className }: Props) => {
    const { t } = useTranslationSSR();
    const { setIsMailingServicesFAQModalOpen, setIsMailingServicesOptionsModalOpen } = useMailingServicesContext();
    const isMailingServiceCompatibleProduct = useIsMailingServicesCompatibleProduct();
    const isMailingServicesEnabled = useIsMailingServicesEnabled();
    const mailingIcon = isMailingServicesEnabled ? (
        <MailingServiceActiveIcon data-testid="mailing-service-enabled" aria-hidden="true" />
    ) : (
        <MailingIcon data-testid="mailing-service-disabled" aria-hidden="true" />
    );
    const labelContent = t(mailingServicesMessages.mailingButton.id);
    const { isItemInCart } = useCartContext();
    const trackingClient = useTrackingClient();

    const openMailingServicesFAQModal = useCallback(() => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MAILING_SERVICES_BUTTON,
            label: "Open mailing services faq modal"
        });
        setIsMailingServicesFAQModalOpen(true);
    }, [setIsMailingServicesFAQModalOpen, trackingClient]);

    const openMailingServicesOptionsModal = useCallback(() => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_NON_MAILING_SERVICES_BUTTON,
            label: "Open mailing services options modal"
        });
        setIsMailingServicesOptionsModalOpen(true);
    }, [setIsMailingServicesOptionsModalOpen, trackingClient]);

    const handleMailServiceButtonClick = useCallback(() => {
        isMailingServicesEnabled ? openMailingServicesFAQModal() : openMailingServicesOptionsModal();
    }, [isMailingServicesEnabled, openMailingServicesOptionsModal, openMailingServicesFAQModal]);

    return isMailingServiceCompatibleProduct && !isItemInCart ? (
        <GenericHeaderButton
            className={className}
            icon={mailingIcon}
            label={labelContent}
            onClick={handleMailServiceButtonClick}
            data-testid="mailing-button"
        />
    ) : (
        <></>
    );
});

MailingButton.displayName = "MailingButton";
