import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import { Hidden, Typography } from "@vp/swan";
import { observer } from "mobx-react-lite";
import { ExpandingButton, ExpandingButtonHandles } from "@internal/ui-studio-chrome";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { MyProjectsButton } from "@shared/features/MyProjects";
import { useExpandingButtonCallback } from "@shared/features/ExpandingButton";
import { DownloadButton } from "@shared/features/GlobalHeader";
import { DuplicateProjectButton, RenameProjectButton, SaveButtonSmallScreen } from "@shared/features/Save";
import { useTrackingClient, Events } from "@internal/utils-tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { SpecsAndTemplatesButton, useProductHasSpecsAndTemplates } from "@shared/features/SpecsAndTemplates";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useStudioConfigurationManager } from "@internal/dex";
import * as styles from "./ProjectNameDropdown.module.scss";

const ProjectNameDropdownContent = observer(({ onSave }: { onSave: () => void }) => {
    const { isMedium } = useStudioLayout();
    const hasSpecsAndTemplate = useProductHasSpecsAndTemplates();
    const { shouldAllowUserSave, shouldAllowMyProjects, shouldAllowRenameDesign, shouldAllowDuplicateDesign } =
        useStudioConfigurationManager().data;

    return (
        <div data-testid="ProjectNameDropdownContent">
            {isMedium && shouldAllowUserSave && (
                <SaveButtonSmallScreen className={styles.projectNameDropdownButton} onSave={onSave} />
            )}
            {shouldAllowMyProjects && (
                <MyProjectsButton
                    className={classNames(styles.projectNameDropdownButton, styles.projectNameDropdownButtonMyProjects)}
                    enableModalTransitionEvent
                />
            )}
            {shouldAllowRenameDesign && <RenameProjectButton className={styles.projectNameDropdownButton} />}
            {shouldAllowDuplicateDesign && <DuplicateProjectButton className={styles.projectNameDropdownButton} />}
            <DownloadButton className={styles.projectNameDropdownButton} skin="unstyled" />
            {hasSpecsAndTemplate && (
                <SpecsAndTemplatesButton className={styles.projectNameDropdownButton} skin="unstyled" />
            )}
        </div>
    );
});

export const ProjectNameDropdown = observer(() => {
    const { workName, productName } = useProductAndProjectStateManager().data;
    const { buttonCallback } = useExpandingButtonCallback();
    const trackingClient = useTrackingClient();

    const buttonRef = useRef<ExpandingButtonHandles>(null);

    const { shouldAllowMyProjects } = useStudioConfigurationManager().data;

    // close dropdown when clicking save in dropdown (shown on medium screens only)
    const onSave = useCallback(() => {
        buttonRef.current?.close();
    }, []);

    return (
        <ExpandingButton
            className={classNames("project-name-button")}
            ref={buttonRef}
            title={workName || productName}
            content={<ProjectNameDropdownContent onSave={onSave} />}
            showArrow={true}
            displayType="multirow"
            overridePopperAlignment="start"
            buttonCallback={buttonCallback}
            buttonClassName={styles.projectNameButton}
            containerClassName={styles.projectNameButtonDropdownContainer}
            contentClassName={styles.projectNameDropdownContent}
            data-testid="projectNameButton"
            onOpen={() => {
                trackingClient.track(Events.DesignToolUsed, {
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_OPEN_PROJECT_DROPDOWN,
                    label: "Open Project Dropdown"
                });
            }}
        >
            <Hidden sm className={classNames("project-name-button__dash", styles.projectNameButtonDash)}>
                {shouldAllowMyProjects && <Typography fontSize="small">–</Typography>}
            </Hidden>
            <Typography fontSize="small" className={styles.projectNameButtonWorkname}>
                {workName || productName}
            </Typography>
        </ExpandingButton>
    );
});
