import { FontFlavor } from "@design-stack-ct/font-sdk";
import { FontConfiguration } from "@design-stack-vista/core-features";
import { isEmbroidery } from "@internal/utils-deco-tech";
import { SimplifiedEaselConfig } from "@internal/utils-product-loading-provider-core";
import {
    FONT_REPOSITORY_URL,
    getFontNames,
    DEFAULT_FONT_FAMILY,
    DEFAULT_MAX_FONT_SIZE,
    MIN_FONT_SIZE_BY_FONT_FLAVOR,
    getMinMaxFontSizes
} from "@internal/utils-fonts";

export function getFontConfigurationFromEaselConfig(config: SimplifiedEaselConfig): FontConfiguration {
    const { minFontSize: productMinFontSizes } = config.studioConfiguration;
    const { decorationTechnology } = config.cimDoc.document.panels[0];
    const { minFontSize, maxFontSize } = getMinMaxFontSizes(decorationTechnology, productMinFontSizes ?? undefined);
    const isEmbroideredProduct = isEmbroidery(decorationTechnology);
    const fontFamiliesForDecoTech = isEmbroideredProduct ? getFontNames().embroidery : getFontNames().print;
    const defaultFamilyForDecoTech = isEmbroideredProduct ? DEFAULT_FONT_FAMILY.embroidery : DEFAULT_FONT_FAMILY.print;
    return {
        fontRepositoryUrl: FONT_REPOSITORY_URL,
        fontFamilies: fontFamiliesForDecoTech,
        defaultFontFamily: defaultFamilyForDecoTech,
        // instead of font flavor this may need to be tied to process type since print can have many different configurations
        fontSizeConfigurations: {
            [FontFlavor.PRINT]: {
                minPtSize: minFontSize,
                maxPtSize: maxFontSize,
                minSizeOption: minFontSize,
                // rich-text-manager will determine maxSizeOption based on current panel size
                sizeOptionIntervals: 40
            },
            [FontFlavor.EMBROIDERY]: {
                minPtSize: MIN_FONT_SIZE_BY_FONT_FLAVOR.embroidery,
                maxPtSize: DEFAULT_MAX_FONT_SIZE,
                minSizeOption: MIN_FONT_SIZE_BY_FONT_FLAVOR.embroidery,
                // rich-text-manager will determine maxSizeOption based on current panel size
                sizeOptionIntervals: 40
            }
        }
    };
}
