import { defineMessages } from "@vp/i18n-helper";

export const messages = defineMessages({
    bleedArea: {
        id: "editorUI.viewPanel.bleedArea",
        defaultMessage: "Bleed Area",
        description: {
            note: "Label for bleed area toggle"
        }
    },
    bleedAreaDescription: {
        id: "editorUI.viewPanel.bleedAreaDescription",
        defaultMessage: "Designable area.",
        description: {
            note: "Description for bleed area toggle"
        }
    },
    cutLines: {
        id: "editorUI.viewPanel.cutLines",
        defaultMessage: "Cutlines",
        description: {
            note: "Label for cutlines area toggle"
        }
    },
    cutLinesDescription: {
        id: "editorUI.viewPanel.cutLinesDescription",
        defaultMessage: "Where the cuts are made.",
        description: {
            note: "Description for cutlines area toggle"
        }
    },
    foldingLine: {
        id: "editorUI.viewPanel.foldingLine",
        defaultMessage: "Folding line",
        description: {
            note: "Label for folding line  area toggle"
        }
    },
    foldingLineDescription: {
        id: "editorUI.viewPanel.foldingLineDescription",
        defaultMessage: "Your product will be folded here.",
        description: {
            note: "Description for folding line area toggle"
        }
    },
    safeLine: {
        id: "editorUI.viewPanel.safeLine",
        defaultMessage: "Safe Line",
        description: {
            note: "Label for safe line area toggle"
        }
    },
    safeLineDescription: {
        id: "editorUI.viewPanel.safeLineDescription",
        defaultMessage: "All the contents should be placed in this area.",
        description: {
            note: "Description for safe line area toggle"
        }
    },
    trimLine: {
        id: "editorUI.viewPanel.trimLine",
        defaultMessage: "Trim line",
        description: {
            note: "Label for trim line area toggle"
        }
    },
    trimLineDescription: {
        id: "editorUI.viewPanel.trimLineDescription",
        defaultMessage: "Where the cuts are made.",
        description: {
            note: "Description for trim line area toggle"
        }
    },
    on: {
        id: "studio.ui.component.viewPanel.on",
        defaultMessage: "On",
        description: {
            note: "Toggle switched On"
        }
    },
    off: {
        id: "studio.ui.component.viewPanel.off",
        defaultMessage: "Off",
        description: {
            note: "Toggle switched Off"
        }
    }
});
