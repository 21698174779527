import React, { ChangeEvent, useCallback } from "react";
import {
    Typography,
    Callout,
    SelectionSetTileImageContainer,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    SelectionSetTileContents,
    SelectionSetTileName,
    SelectionSetTilePrice,
    SelectedValue,
    Box,
    SelectedValues
} from "@vp/swan";
import { JpgIcon, PngIcon, PdfIcon } from "@internal/ui-studio-chrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DownloadConfig, DownloadFormats, RESOLUTIONS } from "@six/experiments/digital/DownloadFlow/types";
import * as styles from "./downloadFlowModalBody.module.scss";

const messages = defineMessages({
    downloadFlowModalMainFormatTitle: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.format.title",
        defaultMessage: "Select Format"
    },
    downloadFlowModalMainFormatRecommended: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.format.recommended",
        defaultMessage: "Recommended"
    },
    downloadFlowModalMainFormatJpg: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.format.jpg",
        defaultMessage: "Standard for web and print"
    },
    downloadFlowModalMainFormatPng: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.format.png",
        defaultMessage: "High-quality image for web use"
    },
    downloadFlowModalMainFormatPdf: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.format.pdf",
        defaultMessage: "Good for printing and sharing"
    },
    downloadFlowModalMainQualityTitle: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.quality.title",
        defaultMessage: "Image Quality"
    },
    downloadFlowModalMainQualityStandard: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.quality.standard",
        defaultMessage: "Standard"
    },
    downloadFlowModalMainQualityHight: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.quality.hight",
        defaultMessage: "Hight quality"
    },
    downloadFlowModalMainTransparentBackground: {
        id: "studioSix.experiments.digital.downloadFlowModal.main.transparentBackground",
        defaultMessage: "Transparent background"
    }
});

type Props = {
    changeFormats: (selectedValue: DownloadFormats, event: ChangeEvent<HTMLInputElement>) => void;
    changeSizes: (selectedValue: SelectedValue, event: ChangeEvent<HTMLInputElement>) => void;
    setTransparentBackground: (selectedValue: boolean) => void;
    downloadConfig: DownloadConfig;
};

export const DownloadFlowModalBody = (props: Props) => {
    const { changeFormats, changeSizes, setTransparentBackground, downloadConfig } = props;
    const { t } = useTranslationSSR();

    const handleSelectedValuesChange = useCallback(
        (newSelectedValues: SelectedValues) => {
            setTransparentBackground(newSelectedValues.isTransparent);
        },
        [setTransparentBackground]
    );

    return (
        <>
            <Typography fontWeight="bold" fontSize="small" mb={"4"}>
                {t(messages.downloadFlowModalMainFormatTitle.id)}
            </Typography>
            {/* we need this wrapper to make 1 column layout for SelectionSet[skin="tiles-mini"] and some small changes */}
            <Box className={styles.imageFormatsWrapper}>
                <SelectionSet
                    className={styles.formats}
                    defaultSelectedValue={downloadConfig.format}
                    skin="tiles-mini"
                    imageWidth="fixed"
                    imageVerticalAlignment="center"
                    tileImagePadding="wide"
                    mb={"4"}
                    onSelectedValueChange={changeFormats}
                >
                    <SelectionSetInput value="jpg">
                        <SelectionSetLabel className={styles.label} mb={"4"} mr={0}>
                            <SelectionSetTileImageContainer className={styles.image}>
                                <JpgIcon width={24} height={24} />
                            </SelectionSetTileImageContainer>
                            <SelectionSetTileContents>
                                <SelectionSetTileName>JPG</SelectionSetTileName>
                                <SelectionSetTilePrice>
                                    {t(messages.downloadFlowModalMainFormatJpg.id)}
                                </SelectionSetTilePrice>
                            </SelectionSetTileContents>
                        </SelectionSetLabel>
                    </SelectionSetInput>
                    <SelectionSetInput value="png">
                        <SelectionSetLabel className={styles.label} mb={"4"} mr={0}>
                            <SelectionSetTileImageContainer className={styles.image}>
                                <PngIcon width={24} height={24} />
                            </SelectionSetTileImageContainer>
                            <SelectionSetTileContents>
                                <Callout className={styles.callout} size="mini">
                                    {t(messages.downloadFlowModalMainFormatRecommended.id)}
                                </Callout>
                                <SelectionSetTileName>PNG</SelectionSetTileName>
                                <SelectionSetTilePrice>
                                    {t(messages.downloadFlowModalMainFormatPng.id)}
                                </SelectionSetTilePrice>
                            </SelectionSetTileContents>
                        </SelectionSetLabel>
                    </SelectionSetInput>
                    <SelectionSetInput value="pdf">
                        <SelectionSetLabel className={styles.label} mb={"4"} mr={0}>
                            <SelectionSetTileImageContainer className={styles.image}>
                                <PdfIcon width={21} height={24} />
                            </SelectionSetTileImageContainer>
                            <SelectionSetTileContents>
                                <SelectionSetTileName>PDF</SelectionSetTileName>
                                <SelectionSetTilePrice>
                                    {t(messages.downloadFlowModalMainFormatPdf.id)}
                                </SelectionSetTilePrice>
                            </SelectionSetTileContents>
                        </SelectionSetLabel>
                    </SelectionSetInput>
                </SelectionSet>
            </Box>
            {(downloadConfig.format === "jpg" || downloadConfig.format === "png") && (
                <>
                    <Typography fontWeight="bold" fontSize="small" mb={"4"}>
                        {t(messages.downloadFlowModalMainQualityTitle.id)}
                    </Typography>
                    <Box className={styles.imageQualitiesWrapper}>
                        <SelectionSet
                            skin="tiles-mini"
                            defaultSelectedValue={RESOLUTIONS.standard}
                            selectedValue={downloadConfig.resolution}
                            onSelectedValueChange={changeSizes}
                            mb={"4"}
                        >
                            <SelectionSetInput value={RESOLUTIONS.standard}>
                                <SelectionSetLabel>
                                    <SelectionSetTileContents>
                                        <SelectionSetTileName>
                                            {t(messages.downloadFlowModalMainQualityStandard.id)}
                                        </SelectionSetTileName>
                                        <SelectionSetTilePrice>
                                            {downloadConfig.width}x{downloadConfig.height} px
                                        </SelectionSetTilePrice>
                                    </SelectionSetTileContents>
                                </SelectionSetLabel>
                            </SelectionSetInput>
                            <SelectionSetInput value={RESOLUTIONS.high}>
                                <SelectionSetLabel>
                                    <SelectionSetTileContents>
                                        <SelectionSetTileName>
                                            {t(messages.downloadFlowModalMainQualityHight.id)}
                                        </SelectionSetTileName>
                                        <SelectionSetTilePrice>
                                            {downloadConfig.width * 2}x{downloadConfig.height * 2} px
                                        </SelectionSetTilePrice>
                                    </SelectionSetTileContents>
                                </SelectionSetLabel>
                            </SelectionSetInput>
                        </SelectionSet>
                    </Box>
                </>
            )}
            {downloadConfig.format === "png" && (
                <SelectionSet
                    skin="simple-column"
                    variant="multi-select"
                    onSelectedValuesChange={handleSelectedValuesChange}
                    selectedValues={{ isTransparent: downloadConfig.isTransparent }}
                    aria-label={t(messages.downloadFlowModalMainTransparentBackground.id)}
                >
                    <SelectionSetInput value="isTransparent">
                        <SelectionSetLabel fontSize="small">
                            {t(messages.downloadFlowModalMainTransparentBackground.id)}
                        </SelectionSetLabel>
                    </SelectionSetInput>
                </SelectionSet>
            )}
        </>
    );
};
