import React, { ReactNode, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useUploadManager } from "@design-stack-vista/upload-components";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { setAlerts, resetAlerts, useAppDispatch } from "@shared/redux";
import { QRUploadManagerProvider } from "@vp/qr-mobile-upload";
import { assetPollingInterval, qrCodeExpireOn } from "@shared/features/QRUrlUploads";
import random from "lodash/random";

export interface QRUploadsWrapperProps {
    children: ReactNode | ReactNode[];
}

export const QRUploadsWrapper = observer(({ children }: QRUploadsWrapperProps) => {
    const productAndProjectStateManager = useProductAndProjectStateManager();
    const { locale } = productAndProjectStateManager.data;

    const { auth, isIdentityInitialized } = useIdentityContext();

    const { assetStore } = useUploadManager();
    const dispatch = useAppDispatch();
    const authToken = auth.getToken();
    const authProvider = useMemo(
        () => ({
            getAuthHeaders: async () => ({
                Authorization: `Bearer ${authToken}`
            })
        }),
        [authToken]
    );

    const onCompleted = useCallback(
        (message: string) => {
            dispatch(
                setAlerts({
                    alerts: [
                        {
                            message,
                            skin: "positive",
                            key: `uniq-key ${random(1000)}`
                        }
                    ]
                })
            );
            setTimeout(() => dispatch(resetAlerts()), 5000);
        },
        [dispatch]
    );

    const onError = useCallback(
        (err: Error) => {
            dispatch(
                setAlerts({
                    alerts: [
                        {
                            message: err?.message || "Image loading error",
                            skin: "error",
                            key: "no-key"
                        }
                    ]
                })
            );
            setTimeout(() => dispatch(resetAlerts()), 5000);
        },
        [dispatch]
    );

    if (!isIdentityInitialized || !assetStore) {
        return <>{children}</>;
    }

    return (
        <QRUploadManagerProvider
            assetStore={assetStore}
            authProvider={authProvider}
            assetPollingInterval={assetPollingInterval}
            qrCodeExpireOn={`PT${qrCodeExpireOn}S`}
            locale={locale}
            onEachUploadCompleted={onCompleted}
            onError={onError}
        >
            {children}
        </QRUploadManagerProvider>
    );
});
