import { useMemo } from "react";
// `structuredClone` is not yet supported in Safari 15.0; see https://vistaprint.atlassian.net/wiki/spaces/NTEO/pages/60132385/Vistaprint+browser+support+
// eslint-disable-next-line
import cloneDeep from "lodash/cloneDeep";
import { isEmbroidery, isSingleColor } from "@internal/utils-deco-tech";
import { useStudioConfigurationManager } from "@internal/dex";
import { useIsFlagEnabled } from "@internal/utils-flags";
import type { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { ValidationsConfiguration } from "@six/features/editorUI";
import {
    defaultEmbroideryValidationsConfiguration,
    defaultSingleColorValidationsConfiguration,
    defaultValidationsConfiguration,
    disableAllValidationsConfiguration
} from "../configuration";

export interface ValidationsConfig {
    designEngine?: InteractiveDesignEngine;
    isMailingPostcard: boolean;
}

export function useValidationsConfig({ designEngine, isMailingPostcard }: ValidationsConfig): ValidationsConfiguration {
    const { shouldUseBetweenBoundariesValidation, shouldUseOutsideMarginsValidation, productMinDpi } =
        useStudioConfigurationManager().data;
    const validationsDisabled = useIsFlagEnabled("disableValidations");

    return useMemo(() => {
        const decoTech = designEngine?.cimDocStore.panels[0].panelProperties.decorationTechnology;

        // currently only used for developers to disable validations for testing
        if (validationsDisabled) {
            return disableAllValidationsConfiguration;
        }

        // Right now this disables certain print-specific validations based on the first panel being embroidery
        // TODO: We should clean up validations and make them configurable by panel
        if (isEmbroidery(decoTech)) {
            return defaultEmbroideryValidationsConfiguration;
        }

        if (isSingleColor(decoTech)) {
            return defaultSingleColorValidationsConfiguration;
        }

        const validationsConfig = cloneDeep(defaultValidationsConfiguration);
        validationsConfig.betweenBoundaries.enabled = shouldUseBetweenBoundariesValidation;
        validationsConfig.outsideMargins.enabled = shouldUseOutsideMarginsValidation;
        validationsConfig.imageResolution.config.minimumPpi = parseInt(productMinDpi, 10);
        validationsConfig.outsideMargins.showMailingValidation = isMailingPostcard;

        return validationsConfig;
    }, [
        designEngine,
        isMailingPostcard,
        productMinDpi,
        shouldUseBetweenBoundariesValidation,
        shouldUseOutsideMarginsValidation,
        validationsDisabled
    ]);
}
