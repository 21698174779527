import React from "react";
import { useAppDispatch, useAppSelector, setShowGridlines } from "@shared/redux";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { ViewToggleRow } from "@shared/features/ViewPanel";
import { GridsIcon } from "@internal/ui-studio-chrome";

const messages = defineMessages({
    grids: {
        id: "editorUI.viewPanel.grids",
        defaultMessage: "Grids",
        description: {
            note: "Label for grids toggle"
        }
    },
    gridLinesDescription: {
        id: "editorUI.viewPanel.gridLinesDescription",
        defaultMessage: "Used to align your design.",
        description: {
            note: "Description for grid lines toggle"
        }
    }
});

export function GridToggle() {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const showGridlines = useAppSelector(state => state.showGridlines);

    return (
        <ViewToggleRow
            icon={<GridsIcon />}
            label={t(messages.grids.id)}
            labelId="gridsLabel"
            activated={showGridlines}
            onRequestActivatedChange={activated => dispatch(setShowGridlines(activated))}
            description={t(messages.gridLinesDescription.id)}
            data-testid="viewPanelToggle-GRID"
        />
    );
}
