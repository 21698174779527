import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, FlexBox, H3, ModalDialogBody, ModalDialogFooter, Typography } from "@vp/swan";
import React, { MouseEvent } from "react";
import * as styles from "./downloadFlowErrorPanel.module.scss";

export type DownloadFlowErrorPanelPropTypes = {
    onRetry?: () => void;
};

const messages = defineMessages({
    downloadFlowModalErrorHeader: {
        id: "studioSix.experiments.digital.downloadFlowModal.error.header",
        defaultMessage: "Whoops - something unexpected just happened."
    },
    downloadFlowModalErrorSubheader: {
        id: "studioSix.experiments.digital.downloadFlowModal.error.subheader",
        defaultMessage: "You can try downloading again, or use another format. "
    },
    downloadFlowModalErrorTryAgainButtonText: {
        id: "studioSix.experiments.digital.downloadFlowModal.error.tryAgainButton.text",
        defaultMessage: "Try again"
    },
    downloadFlowModalErrorNote: {
        id: "studioSix.experiments.digital.downloadFlowModal.error.note",
        defaultMessage: "If the problem persists, refresh this page."
    }
});

export const DownloadFlowErrorPanel = (props: DownloadFlowErrorPanelPropTypes) => {
    const { onRetry } = props;
    const { t } = useTranslationSSR();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (onRetry) {
            onRetry();
        }
    };

    return (
        <>
            <ModalDialogBody className={styles.modalBody} mb={0}>
                <FlexBox
                    alignItems="center"
                    flexDirection="column"
                    gap={"6"}
                    justifyContent="center"
                    textAlign="center"
                    className={styles.hFull}
                >
                    <FlexBox alignItems="center" flexDirection="column" justifyContent="center" gap={"4"}>
                        <H3 mb={0}>{t(messages.downloadFlowModalErrorHeader.id)}</H3>
                        <Typography fontSize="standard">{t(messages.downloadFlowModalErrorSubheader.id)}</Typography>
                    </FlexBox>
                    <Button aria-label={t(messages.downloadFlowModalErrorTryAgainButtonText.id)} onClick={handleClick}>
                        {t(messages.downloadFlowModalErrorTryAgainButtonText.id)}
                    </Button>
                    <Typography fontSize="xsmall">{t(messages.downloadFlowModalErrorNote.id)}</Typography>
                </FlexBox>
            </ModalDialogBody>
            {/* We need this empty pinned footer because without it, the parent doesn't get the 'swan-modal-dialog-panel-pinned' class, which adds flexbox. */}
            <ModalDialogFooter pinned />
        </>
    );
};
