import React from "react";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { FlexBox, FieldSet, FieldSetLegend } from "@vp/swan";
import { useShowPanelSections } from "@shared/features/PanelSections";
import { RulerToggle } from "./RulerToggle";
import { GridToggle } from "./GridToggle";
import { MaskGuidesToggle } from "./MaskGuidesToggle";
import { ViewPanelItems } from "./ViewPanelItems";
import * as styles from "./ViewPanelContent.module.scss";

const messages = defineMessages({
    viewPanel: {
        id: "editorUI.viewPanel.legend",
        defaultMessage: "View",
        description: {
            note: "Title for the view panel"
        }
    }
});

export function ViewPanelContent({ className }: React.HTMLProps<HTMLDivElement>) {
    const { t } = useTranslationSSR();
    const shouldShowPanelSections = useShowPanelSections();

    return (
        <FlexBox
            className={classNames(styles.guidePanelContent, className)}
            flexDirection="column"
            data-testid="view-panel-content"
        >
            <FieldSet className={styles.guidePanelScrollableContent}>
                <FieldSetLegend visuallyHidden>{t(messages.viewPanel.id)}</FieldSetLegend>
                <GridToggle />
                <RulerToggle />
                {shouldShowPanelSections ? <ViewPanelItems /> : <MaskGuidesToggle />}
            </FieldSet>
        </FlexBox>
    );
}

ViewPanelContent.displayName = "ViewPanelContent";
