import React from "react";
import { setShowBleed, setShowSafetyArea, useAppDispatch, useAppSelector } from "@shared/redux";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { ViewToggleRow } from "@shared/features/ViewPanel";
import { batch } from "react-redux";

const messages = defineMessages({
    bleedSafety: {
        id: "editorUI.viewPanel.bleedSafety",
        defaultMessage: "Safety area & Bleed",
        description: {
            note: "Label for bleed & safety area toggle"
        }
    },
    bleedSafetyDescription: {
        id: "editorUI.viewPanel.bleedSafetyDescription",
        defaultMessage: "Text and images should fit inside, anything outside the line will get cut.",
        description: {
            note: "Description for bleed & safety area toggle"
        }
    }
});

export function MaskGuidesToggle() {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const showSafetyArea = useAppSelector(state => state.showSafetyArea);
    const showBleed = useAppSelector(state => state.showBleed);

    return (
        <ViewToggleRow
            label={t(messages.bleedSafety.id)}
            labelId="safetyAndBleedAreaLabel"
            activated={showSafetyArea && showBleed}
            onRequestActivatedChange={activated => {
                batch(() => {
                    dispatch(setShowSafetyArea(activated));
                    dispatch(setShowBleed(activated));
                });
            }}
            description={t(messages.bleedSafetyDescription.id)}
        />
    );
}
