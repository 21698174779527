/* eslint-disable max-len */
import React, { Suspense, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import {
    DesignEngineProvider,
    EXPERIMENTAL_FUSION_OPTIONS_TOKEN,
    FUSION_REFERRER_TOKEN,
    FUSION_TEXT_OPTIONS_TOKEN,
    SnappingManager,
    SnappingManagerProvider
} from "@design-stack-vista/core-features";
import { LayoutContainersProvider } from "@design-stack-vista/ida-framework";
import { useInitializeEffect } from "@design-stack-vista/utility-react";
import type { FontConfiguration } from "@design-stack-vista/core-features";
import { lazily } from "react-lazily";
import { lazyRetry, REQUESTOR } from "@internal/utils-network";
import {
    CoreExperienceProps,
    PrintConfiguration,
    SceneConfiguration,
    CustomDragThumbnailLayer,
    CanvasValidationsProviderWrapper,
    ValidationStoreProvider,
    useColorPickerLocalizations,
    RootContextProvider,
    InlineCropLocalizationProvider,
    SingleColorLocalizationProvider
} from "@six/features/editorUI";
import {
    StockImagesProvider,
    DEFAULT_PAGE_SIZE,
    ImageLibraryProvider,
    VectorProvider
} from "@internal/utils-image-library";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppDispatch, useAppSelector } from "@shared/redux";
import { type DesignRequirements, DesignRequirementsProvider, UpsellProvider } from "@shared/features/Product";
import { Studio6PreviewsProviderAdapter } from "@six/features/previews/Studio6PreviewsProviderAdapter";
import { SurfaceSpecsProvider } from "@shared/features/SurfaceSpecifications";
import { PricingProvider, LocalizedProvider as LocalizedPricingProvider } from "@internal/feature-pricing";
import { DesignDialogProvider } from "@six/features/DesignPanel";
import { MailingServicesProvider, POSTCARD_PRDS } from "@shared/features/MailingServices";
import { TableSubselectionProvider } from "@shared/features/Tables";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ActiveFlexibilityProviderStudio6 } from "@six/features/Flexibility";
import { useUpdateSaveStatus } from "@six/features/Save";
import {
    getStandardColorPalette,
    getEmbroideryColorPalette,
    getSingleColorColorPalette,
    EmbroideryColorPalette,
    SingleColorColorPalette,
    GenericColorPalette,
    ColorPaletteProvider
} from "@internal/feature-color-palette";

import { StudioLiveProvider } from "@six/features/StudioLive";
import { ImagePreviewModalProvider, UploadsWrapper, QRUploadsWrapper } from "@six/features/editorUI/imageContentPanel";
import { useLocalizationProvider } from "@six/features/Translation";
import { GalleryProvider } from "@shared/features/ChangeTemplate";
import { ContextualToolbarProviderStudio6 } from "@shared/features/ContextualToolbar";
import { KeyboardShortcutsProvider } from "@shared/features/KeyboardShortcuts";
import { LocalizedProvider as ColorPickerLocalizedProvider } from "@internal/feature-color-picker";
import { useSetupEventTracking } from "@six/features/Tracking/useSetupTracking";
import { useTrackingClient } from "@internal/utils-tracking";
import { useShowPanelSections } from "@shared/features/PanelSections";
import {
    ScreenReaderInstructions,
    IDA_CANVAS_INSTRUCTIONS_ID,
    screenReadermessages
} from "@six/features/Accessibility";
import { useTranslationSSR } from "@vp/i18n-helper";
import { GraphicsPanelProvider } from "@six/features/Graphics";
import { observer } from "mobx-react-lite";
import { isDebugMode } from "@internal/utils-browser";
import { PrintColorProductOptions } from "@shared/features/Product";
import {
    decorateDesignEngineWithExtensions,
    cleanUpDesignEngineExtensions
} from "@six/features/StaticDesignExtensions";
import { useSimHandleDesignEngineExtensionRegistration } from "@six/features/sims";
import { PanelSectionData } from "@internal/feature-panel-sections";
import { useInstantUploadStore } from "@six/features/UploadsAndAssets/InstantUploadStore/InstantUploadStoreProvider";
import { AITemplatesProviderWrapper } from "@six/features/editorUI/aiTemplates";
import { usePreloadDefaultSlot } from "@six/features/ActiveDialog";
import { isFlagEnabled, useIsFlagEnabled } from "@internal/utils-flags";
import { preFetch } from "@mcp-artwork/color-converter";
import { ERROR_CODES, useErrors } from "@internal/utils-errors";
import { SnappingStore } from "@internal/utils-snapping";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { DesignReviewPanelProvider } from "@shared/features/DesignReview/DesignReviewPanelProvider";
import { useDesignAttributeMappings, useCompatibleOptions } from "@shared/features/StudioBootstrap";
import { useStudioConfigurationManager } from "@internal/dex";
import { usePricingLocalizations } from "@six/features/pricing";
import { defaultFontConfiguration } from "./publicApi";
import { GlobalHeader } from "./layout/components/globalHeader";
import { type DesignEngineDependentProvidersProps } from "./DesignEngineDependentProviders";
import { useMarginsForIDA } from "./layout/hooks/useMarginsForIDA";
import { ErrorBoundaryWithSave } from "./Studio6ErrorBoundaryWithSave";
import { useValidationsConfig } from "./publicApi/hooks/useValidationsConfig";
import * as styles from "./DefaultExperience.module.scss";

const DEFAULT_CLIPART_LIMIT = 498; // It is divisible by 3. The maximum limit supported in the SimTag API for topK is 500.

const { DesignEngineDependentContainer } = lazily<{ DesignEngineDependentContainer: React.ComponentType }, any>(() =>
    lazyRetry(() => import("./DesignEngineDependentContainer"))
);

const { DesignEngineDependentProviders } = lazily<
    { DesignEngineDependentProviders: React.ComponentType<DesignEngineDependentProvidersProps> },
    any
>(() => lazyRetry(() => import("./DesignEngineDependentProviders")));

interface DefaultExperienceProps extends CoreExperienceProps {
    designRequirements?: DesignRequirements;
    panelSections?: PanelSectionData[];
    fontConfiguration?: FontConfiguration;
    sceneConfiguration?: SceneConfiguration;
}

export const DefaultExperience = observer(
    ({
        designEngine,
        designRequirements,
        panelSections,
        fontConfiguration = defaultFontConfiguration,
        sceneConfiguration
    }: DefaultExperienceProps) => {
        const dispatch = useAppDispatch();
        const localizationProvider = useLocalizationProvider();
        const instantUploadStore = useInstantUploadStore();
        const colorPickerLocalizedValues = useColorPickerLocalizations();
        const pricingLocalizedValues = usePricingLocalizations();
        const { t } = useTranslationSSR();
        const trackingClient = useTrackingClient();
        const { auth, isIdentityInitialized } = useIdentityContext();
        const hasDesigns = useAppSelector(state => state.hasDesigns);
        const { productKey, locale, studioSelectedProductOptions } = useProductAndProjectStateManager().data;
        const [hasColorsPreFetched, setHasColorsPreFetched] = useState<boolean>(false);
        const { handleError } = useErrors();
        const pricingInitialized = useAppSelector(state => state.pricingInitialized);
        const designAttributeMappings = useDesignAttributeMappings();
        const compatibleOptions = useCompatibleOptions();

        const embroideryNewColors = useIsFlagEnabled("embroideryNewColors");

        useSetupEventTracking();
        usePreloadDefaultSlot();

        /**
         * Couldn't directly call useIsMailingPostcard because this component doesn't come under MailingServicesProvider in
         * application tree
         *  */
        const isMailingPostcard = POSTCARD_PRDS.MAILING === productKey;

        const validationsConfiguration = useValidationsConfig({
            designEngine,
            isMailingPostcard
        });

        const idaMargins = useMarginsForIDA(designRequirements?.panels[0]);

        const injectPanelSectionData = useShowPanelSections();

        /**
         * On start of the design experience, we want to decorate the design engine
         * with all the necessary extensions and dependencies required for the features
         * which we will be using.
         *
         * We must also be sure to clean up any of these facets when the design experience is finished so that we do not pollute the design engine
         */
        const { designEngine: initializedDesignEngine, snappingManager } = useInitializeEffect(
            () => {
                // todo(#39): remove try catch and implement clean up once injector supports removing deps injected
                if (designEngine) {
                    try {
                        designEngine.designExtensionSystem.injector.provide(FUSION_REFERRER_TOKEN, REQUESTOR);
                        designEngine.designExtensionSystem.injector.provide("designRequirements", designRequirements);
                        designEngine.designExtensionSystem.injector.provide("renderingTenant", "pde-ref"); // this was previous the current tenant on the document
                        designEngine.designExtensionSystem.injector.provide(EXPERIMENTAL_FUSION_OPTIONS_TOKEN, {
                            fullDocumentSupport: true
                        });
                        if (isFlagEnabled("rtext")) {
                            designEngine?.designExtensionSystem.injector.provide(FUSION_TEXT_OPTIONS_TOKEN, {
                                rtextEnabled: true
                            });
                        }
                        designEngine.designExtensionSystem.injector.provide(
                            "localizationProvider",
                            localizationProvider
                        );
                        designEngine.designExtensionSystem.injector.provide("instantUploadStore", instantUploadStore);
                        designEngine.designExtensionSystem.injector.provide("trackingClient", trackingClient);
                        designEngine.designExtensionSystem.injector.provide("dispatch", dispatch);
                        if (injectPanelSectionData && panelSections) {
                            designEngine.designExtensionSystem.injector.provide("panelSections", panelSections);
                        }
                    } catch (e) {
                        // ignore this because in strict mode we are mounting twice and the todo above will adequately clean this up
                    }

                    // decorate the design engine with required extensions for features used
                    decorateDesignEngineWithExtensions(designEngine);

                    // set the initial view for the end user as we would like
                    designEngine.layoutStore.setPanelLayoutDefinition({
                        type: "calculated",
                        mode: "vertical",
                        panelMargins: [idaMargins]
                    });

                    if (!designEngine.idaStore.activeDesignPanelId) {
                        let firstPanelId = designEngine.cimDocStore.panels[0].id;

                        //  handle packaging products using a BoxUp sku with two color surfaces where only inside is designable
                        if (
                            injectPanelSectionData &&
                            studioSelectedProductOptions.PrintColor === PrintColorProductOptions.insideOnly
                        ) {
                            firstPanelId = designEngine.cimDocStore.panels[1].id;
                        }

                        designEngine.idaStore.setActiveDesignPanelId(firstPanelId);
                        designEngine.layoutStore.setVisiblePanelIds([firstPanelId]);
                    }

                    if (process.env.NODE_ENV !== "production" || isDebugMode() || window.Cypress) {
                        // @ts-ignore
                        globalThis.designEngine = designEngine;
                    }

                    const snappingManager = new SnappingManager(new SnappingStore(designEngine), {
                        thresholds: { angle: 5 }
                    });

                    return { designEngine, snappingManager };
                }
                return { designEngine: undefined, snappingManager: undefined };
            },
            [designEngine],
            oldDesignEngine => {
                if (oldDesignEngine.designEngine) {
                    cleanUpDesignEngineExtensions(oldDesignEngine.designEngine);
                }
            }
        );

        useSimHandleDesignEngineExtensionRegistration(initializedDesignEngine);

        const printConfiguration: PrintConfiguration = useMemo(() => {
            return { validationsConfiguration };
        }, [validationsConfiguration]);

        useUpdateSaveStatus(designEngine);

        const colorPalettes = useMemo(() => {
            return [
                new EmbroideryColorPalette(getEmbroideryColorPalette(locale, embroideryNewColors)),
                new SingleColorColorPalette(
                    getSingleColorColorPalette(
                        locale,
                        hasColorsPreFetched,
                        designRequirements?.panels[0].availablePantoneColors,
                        designRequirements?.panels[0].decorationTechnology,
                        designRequirements?.panels[0].engravingColor
                    )
                ),
                new GenericColorPalette(getStandardColorPalette(locale))
            ];
        }, [designRequirements?.panels, hasColorsPreFetched, locale, embroideryNewColors]);

        // initialize colors to prevent unexpected errors
        useEffect(() => {
            preFetch()
                .then(() => {
                    setHasColorsPreFetched(true);
                })
                .catch(error => {
                    handleError(error, ERROR_CODES.LOAD_COLORS, ENTITY_CODE, false, true, true);
                });
        }, [handleError]);

        return (
            <DesignEngineProvider designEngine={initializedDesignEngine}>
                <ErrorBoundaryWithSave>
                    <UploadsWrapper>
                        <LayoutContainersProvider>
                            <ImageLibraryProvider locale={locale} authToken={auth && auth.getToken()}>
                                <StockImagesProvider
                                    authToken={auth && auth.getToken()}
                                    locale={locale}
                                    pageSize={DEFAULT_PAGE_SIZE}
                                >
                                    <VectorProvider
                                        authToken={auth && auth.getToken()}
                                        locale={locale}
                                        limit={DEFAULT_CLIPART_LIMIT}
                                    >
                                        <DndProvider backend={HTML5Backend}>
                                            <CustomDragThumbnailLayer />
                                            <ColorPaletteProvider colorPalettes={colorPalettes}>
                                                <DesignRequirementsProvider
                                                    studioDesignRequirements={designRequirements}
                                                >
                                                    <GalleryProvider isLive={hasDesigns}>
                                                        <UpsellProvider>
                                                            <SurfaceSpecsProvider>
                                                                <LocalizedPricingProvider
                                                                    locale={locale}
                                                                    localizedValues={pricingLocalizedValues}
                                                                >
                                                                    <PricingProvider
                                                                        useStudioConfigurationManager={
                                                                            useStudioConfigurationManager
                                                                        }
                                                                        compatibleOptions={compatibleOptions}
                                                                        designAttributeMappings={
                                                                            designAttributeMappings
                                                                        }
                                                                        pricingInitialized={pricingInitialized}
                                                                    >
                                                                        <TableSubselectionProvider>
                                                                            <ActiveFlexibilityProviderStudio6>
                                                                                <ImagePreviewModalProvider>
                                                                                    <GraphicsPanelProvider>
                                                                                        <MailingServicesProvider>
                                                                                            {isIdentityInitialized && (
                                                                                                <ValidationStoreProvider
                                                                                                    config={
                                                                                                        printConfiguration.validationsConfiguration
                                                                                                    }
                                                                                                >
                                                                                                    <CanvasValidationsProviderWrapper>
                                                                                                        <ContextualToolbarProviderStudio6>
                                                                                                            <DesignReviewPanelProvider>
                                                                                                                <DesignDialogProvider>
                                                                                                                    <RootContextProvider
                                                                                                                        context={{
                                                                                                                            printConfig:
                                                                                                                                printConfiguration,
                                                                                                                            sceneConfiguration
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Studio6PreviewsProviderAdapter>
                                                                                                                            <QRUploadsWrapper>
                                                                                                                                <StudioLiveProvider>
                                                                                                                                    <KeyboardShortcutsProvider>
                                                                                                                                        <ColorPickerLocalizedProvider
                                                                                                                                            localizedValues={
                                                                                                                                                colorPickerLocalizedValues
                                                                                                                                            }
                                                                                                                                            locale={
                                                                                                                                                locale
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <AITemplatesProviderWrapper>
                                                                                                                                                <InlineCropLocalizationProvider>
                                                                                                                                                    <SingleColorLocalizationProvider>
                                                                                                                                                        <SnappingManagerProvider
                                                                                                                                                            snappingManager={
                                                                                                                                                                snappingManager
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className={classNames(
                                                                                                                                                                    styles.pdeContainer,
                                                                                                                                                                    {
                                                                                                                                                                        [styles.pdeContainerWithDebug]:
                                                                                                                                                                            isDebugMode()
                                                                                                                                                                    }
                                                                                                                                                                )}
                                                                                                                                                            >
                                                                                                                                                                <GlobalHeader />
                                                                                                                                                                <div
                                                                                                                                                                    className={
                                                                                                                                                                        styles.pdeBody
                                                                                                                                                                    }
                                                                                                                                                                    data-testid="pdeBody"
                                                                                                                                                                >
                                                                                                                                                                    <Suspense
                                                                                                                                                                        fallback={
                                                                                                                                                                            <>

                                                                                                                                                                            </>
                                                                                                                                                                        }
                                                                                                                                                                    >
                                                                                                                                                                        {initializedDesignEngine && (
                                                                                                                                                                            <DesignEngineDependentProviders
                                                                                                                                                                                designEngine={
                                                                                                                                                                                    initializedDesignEngine
                                                                                                                                                                                }
                                                                                                                                                                                fontConfiguration={
                                                                                                                                                                                    fontConfiguration
                                                                                                                                                                                }
                                                                                                                                                                            >
                                                                                                                                                                                <DesignEngineDependentContainer />
                                                                                                                                                                            </DesignEngineDependentProviders>
                                                                                                                                                                        )}
                                                                                                                                                                    </Suspense>
                                                                                                                                                                    <ScreenReaderInstructions
                                                                                                                                                                        id={
                                                                                                                                                                            IDA_CANVAS_INSTRUCTIONS_ID
                                                                                                                                                                        }
                                                                                                                                                                        message={`${t(
                                                                                                                                                                            screenReadermessages
                                                                                                                                                                                .idaWorkArea
                                                                                                                                                                                .id
                                                                                                                                                                        )} ${t(
                                                                                                                                                                            screenReadermessages
                                                                                                                                                                                .idaItems
                                                                                                                                                                                .id
                                                                                                                                                                        )}`}
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </SnappingManagerProvider>
                                                                                                                                                    </SingleColorLocalizationProvider>
                                                                                                                                                </InlineCropLocalizationProvider>
                                                                                                                                            </AITemplatesProviderWrapper>
                                                                                                                                        </ColorPickerLocalizedProvider>
                                                                                                                                    </KeyboardShortcutsProvider>
                                                                                                                                </StudioLiveProvider>
                                                                                                                            </QRUploadsWrapper>
                                                                                                                        </Studio6PreviewsProviderAdapter>
                                                                                                                    </RootContextProvider>
                                                                                                                </DesignDialogProvider>
                                                                                                            </DesignReviewPanelProvider>
                                                                                                        </ContextualToolbarProviderStudio6>
                                                                                                    </CanvasValidationsProviderWrapper>
                                                                                                </ValidationStoreProvider>
                                                                                            )}
                                                                                        </MailingServicesProvider>
                                                                                    </GraphicsPanelProvider>
                                                                                </ImagePreviewModalProvider>
                                                                            </ActiveFlexibilityProviderStudio6>
                                                                        </TableSubselectionProvider>
                                                                    </PricingProvider>
                                                                </LocalizedPricingProvider>
                                                            </SurfaceSpecsProvider>
                                                        </UpsellProvider>
                                                    </GalleryProvider>
                                                </DesignRequirementsProvider>
                                            </ColorPaletteProvider>
                                        </DndProvider>
                                    </VectorProvider>
                                </StockImagesProvider>
                            </ImageLibraryProvider>
                        </LayoutContainersProvider>
                    </UploadsWrapper>
                </ErrorBoundaryWithSave>
            </DesignEngineProvider>
        );
    }
);

DefaultExperience.displayName = "DefaultExperience";
