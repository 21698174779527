import { useCallback, useRef } from "react";
import { setShowSaveAs, showDesignReview, showEditInCartModal, useAppDispatch, useAppSelector } from "@shared/redux";
import { useCartContext } from "@internal/utils-cart";
import { EDIT_FROM_CART_MODAL_TYPE } from "@internal/data-access-cart";
import { useTrackingClient, Events } from "@internal/utils-tracking";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useSave } from "@shared/features/Save";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { useGetDocumentForSave } from "./useGetDocumentForSave";

export function useOnSaveStatus() {
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const { trackSaveStart, trackSaveEnd } = useSaveEventTracking();

    const { workId } = useProductAndProjectStateManager().data;
    const { changesUpdatedModalSeen } = useAppSelector(state => state.editInCartModal);

    const save = useSave({
        onSaveCallback: trackSaveEnd,
        showSaveToast: !(isItemInCart && !changesUpdatedModalSeen), // when showing changes updated modal, hide save toast
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave()
    });

    const onSaveStatusClick = useCallback(async () => {
        trackSaveStart({ editFromCartFlow: isItemInCart });
        // if item is already in cart, the first time user saves, show changes updated modal
        if (isItemInCart && !changesUpdatedModalSeen) {
            dispatch(
                showEditInCartModal({
                    showModal: true,
                    modalType: EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED,
                    callback: () => dispatch(showDesignReview({ show: true })),
                    changesUpdatedModalSeen: true
                })
            );
        }

        if (workId) {
            save();
        } else {
            dispatch(setShowSaveAs({ showSaveAs: true }));
        }
    }, [isItemInCart, workId, dispatch, save, changesUpdatedModalSeen, trackSaveStart]);

    return onSaveStatusClick;
}

function useSaveEventTracking() {
    const trackingClient = useTrackingClient();
    const startTime = useRef<number>(0);

    const trackSaveStart = useCallback(
        (extraData: Record<string, any>) => {
            startTime.current = performance.now();
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SAVE_STATUS_BUTTON,
                label: "Save Status Button",
                extraData: () => extraData
            });
        },
        [trackingClient]
    );

    const trackSaveEnd = useCallback(() => {
        trackingClient.track(Events.InteractionTimed, {
            eventName: "Save",
            timing: performance.now() - startTime.current
        });
    }, [trackingClient]);

    return { trackSaveStart, trackSaveEnd };
}
