import React, { Fragment } from "react";
import classNames from "classnames";
import type { Margins } from "@design-stack-vista/ida-framework";
import { DimensionRuler } from "@design-stack-vista/ida-framework";
import { Measurement, MeasurementUnit, round } from "@design-stack-vista/utility-core";
import type { PanelRulersExtension, RulerSide } from "@design-stack-vista/core-features";
import type { RulerConfig } from "./types";
import * as styles from "./DimensionRulers.module.scss";

function getPositionStyle(side: RulerSide, staticMargins: Margins) {
    switch (side) {
        case "top":
            return { top: 0, transform: `translateY(calc(-100% - ${staticMargins[side]}px))` };
        case "right":
            return { right: 0, transform: `translateX(calc(100% + ${staticMargins[side]}px))` };
        case "bottom":
            return { bottom: 0, transform: `translateY(calc(100% + ${staticMargins[side]}px))` };
        case "left":
            return { left: 0, transform: `translateX(calc(-100% - ${staticMargins[side]}px))` };
        default:
            return {};
    }
}

interface DimensionRulersProps {
    rulersConfig: [RulerSide, RulerConfig][];
    rulers: PanelRulersExtension;
    unit: MeasurementUnit;
    panelStaticMargins: Margins;
    layoutZoom: number;
}

export const DimensionRulers = ({
    rulersConfig,
    rulers,
    unit,
    panelStaticMargins,
    layoutZoom
}: DimensionRulersProps) => (
    <Fragment>
        {rulersConfig.map(([side, rulerConfig]) => {
            const ruler = rulers.getRuler(side, rulerConfig.mask);
            if (!ruler) {
                return null;
            }

            const { length: rulerLength, offset: rulerOffset } = ruler;
            const length = rulerLength * layoutZoom;
            const label = new Measurement(round(new Measurement(rulerLength, MeasurementUnit.MM)[unit], 2), unit)
                .measurement;
            const orientation = side === "top" || side === "bottom" ? "horizontal" : "vertical";
            const positionStyle = getPositionStyle(side, panelStaticMargins);
            positionStyle.transform += ` translate${orientation === "horizontal" ? "X" : "Y"}(${
                rulerOffset * layoutZoom
            }px)`;

            return (
                <div key={side} style={positionStyle}>
                    <DimensionRuler
                        length={length}
                        orientation={orientation}
                        label={rulerConfig.hideLabel ? "" : label}
                        className={classNames(styles.ruler, styles[side])}
                    />
                </div>
            );
        })}
    </Fragment>
);
