export type DownloadFormats = "jpg" | "pdf" | "png";

export const RESOLUTIONS = {
    standard: "standard",
    high: "high"
} as const;

export type Resolution = keyof typeof RESOLUTIONS;

type BaseImageConfig = {
    filename: string;
    resolution: Resolution;
    width: number;
    height: number;
};

export type JpgDownloadConfig = {
    format: "jpg";
    quality: number;
} & BaseImageConfig;

export type PngDownloadConfig = {
    format: "png";
    isTransparent: boolean;
} & BaseImageConfig;

export type PdfDownloadConfig = {
    filename: string;
    format: "pdf";
};

export type DownloadConfig = JpgDownloadConfig | PdfDownloadConfig | PngDownloadConfig;
