import React, { MouseEvent, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { observer } from "mobx-react-lite";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DownloadAnchorButton } from "@six/experiments/digital/DownloadFlow/DownloadFlowModal/DownloadAnchorButton";
import { useGetDesignDocument } from "@six/features/DesignDocument";
import { useGeneratePdf } from "@shared/features/GlobalHeader/DownloadPDF/useGeneratePdf";
import { useGeneratePreview } from "@six/experiments/digital/DownloadFlow/useGeneratePreview";
import { DownloadConfig } from "@six/experiments/digital/DownloadFlow/types";
import debounce from "lodash/debounce";
import { delayPromiseDecorator, promiseWithTimeout, wrapPromise } from "@six/experiments/digital/DownloadFlow/utils";

const messages = defineMessages({
    downloadButtonLabel: {
        id: "studioSix.experiments.digital.downloadButtonLabel",
        defaultMessage: "Download your design"
    },
    downloadButtonText: {
        id: "studioSix.experiments.digital.downloadButtonText",
        defaultMessage: "Download"
    }
});

type DigitalDownloadButtonProps = {
    config: DownloadConfig;
    showDownloadSuccess: () => void;
    abortControllerRef: React.MutableRefObject<AbortController | null>;
};

export const DigitalDownloadButton = observer((props: DigitalDownloadButtonProps) => {
    const { config: downloadConfig, showDownloadSuccess, abortControllerRef } = props;
    const [suspense, setSuspense] = useState<any>(null);
    const { t } = useTranslationSSR();
    const getDocument = useGetDesignDocument({
        action: "Digital Download",
        isTransientDocument: true,
        removeUnreplacedPlaceholders: true
    });
    const { onClickGenerate, error: pdfError } = useGeneratePdf({
        filename: downloadConfig.filename,
        getDocument,
        noProof: true
    });
    const { downloadPreview, error: previewError } = useGeneratePreview({ config: downloadConfig, getDocument });
    const handleError = useErrorHandler();

    if (pdfError || previewError) {
        handleError(pdfError || previewError);
        return null;
    }

    if (suspense) {
        const res = suspense.execute();
        if (!res?.abort) {
            // can execute multiple times
            showDownloadSuccess();
            return null;
        }
    }

    const handleDownload = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        abortControllerRef.current = new AbortController();

        const isImage = downloadConfig.format !== "pdf";
        const promise = isImage
            ? downloadPreview(abortControllerRef.current.signal)
            : onClickGenerate(abortControllerRef.current.signal);

        if (isImage && (await promiseWithTimeout(promise))) {
            showDownloadSuccess();
            return;
        }
        setSuspense(wrapPromise<void>(delayPromiseDecorator(promise)));
    };

    const label = t(messages.downloadButtonLabel.id);
    const text = t(messages.downloadButtonText.id);

    const handleDownloadDebounce = debounce(handleDownload, 2000, { leading: true, trailing: false });
    return (
        <DownloadAnchorButton width="full-width" label={label} onClick={handleDownloadDebounce} mx={0}>
            {text}
        </DownloadAnchorButton>
    );
});
