import { useState, useCallback } from "react";
import { isCareAgent } from "@internal/utils-browser";
import { fireDesignToolTrackingEvent, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { useAppDispatch, setProcessingPDF } from "@shared/redux";
import { getPdfProof } from "@design-stack-ct/prepress-client";
import { retry } from "@internal/utils-network";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { saveTransientDocument } from "@internal/data-access-document-storage";
import { type GetDocument } from "@internal/utils-cimdoc";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { convertToAuthHeaders } from "@design-stack-vista/utility-core";
import { useConvertCutlineDocument } from "@internal/feature-cutline-generation";
import { useStudioConfigurationManager } from "@internal/dex";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

interface Props {
    filename: string;
    getDocument: GetDocument;
    noProof?: boolean;
}

const useGeneratePdf = ({ filename, getDocument, noProof = false }: Props) => {
    const dispatch = useAppDispatch();
    const { auth } = useIdentityContext();
    const [error, setError] = useState<Error | null>(null);
    const { productKey, studioSelectedProductOptions } = useProductAndProjectStateManager().data;
    const designRequirements = useDesignRequirementsContext();
    const { convertToCutlineDocument } = useConvertCutlineDocument();
    const {
        data: { shouldSaveAdaptStickerDocument }
    } = useStudioConfigurationManager();
    const authToken = auth?.getToken();

    const onClickGenerate = useCallback(
        // eslint-disable-next-line consistent-return
        async (abortSignal?: AbortSignal) => {
            const generateStart = performance.now();

            dispatch(setProcessingPDF(true));
            setError(null);

            try {
                const fileSaver = await retry(() => import("file-saver"), { name: "import" });
                const {
                    default: { saveAs }
                } = fileSaver;

                fireDesignToolTrackingEvent({
                    eventDetail: "Generate PDF Proof Button Clicked",
                    label: "Generate PDF Proof Button",
                    pageNameBase: undefined,
                    extraData: undefined
                });

                let document = await getDocument();

                if (shouldSaveAdaptStickerDocument) {
                    document = await convertToCutlineDocument(document, studioSelectedProductOptions, false);
                }

                const documentUrls = await (await saveTransientDocument(document, authToken))._links;

                const prepressInstructionsUrl = documentUrls.drawingInstructions.href;
                const authHeaders = convertToAuthHeaders({ value: authToken, authType: "bearer" });

                const { PdfUrl } = await getPdfProof({
                    prepressInstructionsUrl,
                    headers: authHeaders,
                    watermarkText: isCareAgent() || noProof ? "" : "PROOF",
                    signal: abortSignal
                });

                const response = await fetch(PdfUrl);
                const blob = await response.blob();

                saveAs(blob, filename);

                const generateEnd = performance.now();
                const timeElapsed = generateEnd - generateStart;

                fireDesignToolTrackingEvent({
                    eventDetail: "Generate PDF Proof Processed",
                    label: "Generate PDF Proof Button",
                    pageNameBase: undefined,
                    extraData: () => ({ timeToProcess: timeElapsed })
                });

                const extraData = {
                    productKey,
                    panelsCount: designRequirements?.panels.length
                };

                fireUserInteractionTrackingEvent({
                    eventName: "Click Download PDF Button",
                    timing: timeElapsed,
                    extraData
                });

                dispatch(setProcessingPDF(false));
            } catch (error) {
                if (error.abort) {
                    return error;
                }

                if (!error.message) {
                    error.message = "Unknown Exception from Prepress Client";
                }
                dispatch(setProcessingPDF(false));

                setError(error);

                const generateEnd = performance.now();

                fireDesignToolTrackingEvent({
                    eventDetail: "Generate PDF Proof Failed",
                    label: "Generate PDF Proof Button",
                    pageNameBase: undefined,
                    extraData: () => ({ timeToProcess: generateEnd - generateStart })
                });
            }
        },
        [
            dispatch,
            getDocument,
            shouldSaveAdaptStickerDocument,
            authToken,
            filename,
            productKey,
            designRequirements?.panels.length,
            convertToCutlineDocument,
            studioSelectedProductOptions,
            noProof
        ]
    );

    return { onClickGenerate, error };
};

export { useGeneratePdf };
