import { getCookieConsent } from "@vp/cookie-consent-client-reader";
import { DownloadConfig } from "@six/experiments/digital/DownloadFlow/types";

export type UseDownloadSettingsReturn = [DownloadConfig | null, (settings: DownloadConfig | null | undefined) => void];

const DOWNLOAD_SETTINGS_KEY = "studio-download-settings";

export const isShowSaveSettingsToggleAvailable = (): boolean => {
    const { hasConsent } = getCookieConsent();
    return hasConsent;
};

// Since we're converting the string in local storage to an object on every snapshot, we have to
// keep a cache to ensure we don't return a new object every time even if the value hasn't
// actually change
const snapshotCache = new Map<string, DownloadConfig | null>();

export const store = {
    getSnapshot: () => {
        if (!isShowSaveSettingsToggleAvailable()) {
            return null;
        }

        const raw = localStorage.getItem(DOWNLOAD_SETTINGS_KEY);

        if (!raw) {
            return null;
        }

        if (!snapshotCache.has(raw)) {
            const snapshot = JSON.parse(raw) as DownloadConfig;

            snapshotCache.set(raw, snapshot);

            return snapshot;
        }

        return snapshotCache.get(raw);
    },
    update: (settings: DownloadConfig | null | undefined) => {
        if (isShowSaveSettingsToggleAvailable()) {
            if (settings) {
                localStorage.setItem(DOWNLOAD_SETTINGS_KEY, JSON.stringify(settings));
                window.dispatchEvent(
                    new StorageEvent("storage", { key: DOWNLOAD_SETTINGS_KEY, newValue: JSON.stringify(settings) })
                );
            } else {
                localStorage.removeItem(DOWNLOAD_SETTINGS_KEY);
                window.dispatchEvent(new StorageEvent("storage", { key: DOWNLOAD_SETTINGS_KEY, newValue: null }));
            }
        }
    },
    subscribe: (listener: () => void) => {
        window.addEventListener("storage", listener);
        return () => window.removeEventListener("storage", listener);
    }
};
