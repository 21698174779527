import React, { ReactNode, useCallback, useRef } from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Typography, Button } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { FloppyDiskIcon } from "@internal/ui-studio-chrome";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, showDesignReview, showEditInCartModal } from "@shared/redux";
import { EDIT_FROM_CART_MODAL_TYPE } from "@internal/data-access-cart";
import { useCartContext } from "@internal/utils-cart";
// eslint-disable-next-line import/no-restricted-paths -- dependencies must be extracted but beyond current effort
import { useGetDocumentForSave } from "@six/features/Save/useGetDocumentForSave";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { useSave } from "./useSave";

const messages = defineMessages({
    mobileFileSheetSave: {
        id: "studio.components.myprojects.mobilefilesheet.save",
        defaultMessage: "Save changes",
        description: {
            note: "Button to save project"
        }
    }
});

interface Props {
    className?: string;
    onSave: () => void;
    icon?: ReactNode;
}

export const SaveButtonSmallScreen = observer(({ className, onSave, icon }: Props) => {
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const { t } = useTranslationSSR();
    const { changesUpdatedModalSeen } = useAppSelector(state => state.editInCartModal);
    const startTimeRef = useRef<number>(0);
    const setStartTime = (startTime: number) => {
        startTimeRef.current = startTime;
    };
    const trackSaveTime = useCallback(() => {
        const endTime = performance.now();
        fireUserInteractionTrackingEvent({
            eventName: "Save",
            timing: endTime - startTimeRef.current
        });
    }, []);

    const save = useSave({
        onSaveCallback: useCallback(() => {
            trackSaveTime();
            onSave();
        }, [onSave, trackSaveTime]),
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVE_BUTTON,
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave()
    });

    const onSaveClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        e => {
            e.stopPropagation();
            const startTime = performance.now();
            setStartTime(startTime);
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SAVE_BUTTON,
                label: "Save Button"
            });

            // if item is already in cart, the first time user saves, show changes updated modal
            if (isItemInCart && !changesUpdatedModalSeen) {
                dispatch(
                    showEditInCartModal({
                        showModal: true,
                        modalType: EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED,
                        callback: () => dispatch(showDesignReview({ show: true })),
                        changesUpdatedModalSeen: true
                    })
                );
            }

            save();
        },
        [dispatch, isItemInCart, save, changesUpdatedModalSeen]
    );

    return (
        <Button skin="unstyled" className={classNames("save-changes-btn", className)} onClick={onSaveClick}>
            {icon || <FloppyDiskIcon aria-hidden="true" />}
            <Typography>{t(messages.mobileFileSheetSave.id)}</Typography>
        </Button>
    );
});

SaveButtonSmallScreen.displayName = "SaveButtonSmallScreen";
