import { DesktopExperience, MobileExperience } from "@internal/feature-responsive-design";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioInteractive";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import React, { MouseEvent } from "react";
import Skeleton from "react-loading-skeleton";

export type OpenDownloadFlowButtonProps = {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

const messages = defineMessages({
    openDownloadFlowButtonLabel: {
        id: "studioSix.experiments.digital.openDownloadFlowButton.label",
        defaultMessage: "Select how you want to download your design"
    },
    openDownloadFlowButtonText: {
        id: "studioSix.experiments.digital.openDownloadFlowButtonLabel.text",
        defaultMessage: "Download"
    }
});

export const OpenDownloadFlowButton = (props: OpenDownloadFlowButtonProps) => {
    const { onClick } = props;
    const { t } = useTranslationSSR();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (onClick) {
            onClick(event);
        }
    };

    const label = t(messages.openDownloadFlowButtonLabel.id);
    const text = t(messages.openDownloadFlowButtonText.id);

    return (
        <StudioIsInteractiveSwitch>
            <>
                <DesktopExperience>
                    <Button aria-label={label} skin="primary" onClick={handleClick}>
                        {text}
                    </Button>
                </DesktopExperience>
                <MobileExperience>
                    <Button aria-label={label} size="mini" skin="primary" onClick={handleClick}>
                        {text}
                    </Button>
                </MobileExperience>
            </>
            <DesktopExperience>
                <Skeleton width={169} height={60} style={{ borderRadius: "25px" }} />
            </DesktopExperience>
        </StudioIsInteractiveSwitch>
    );
};
