import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { useAppDispatch, setAlerts, autoClosableAlert, resetAlerts } from "@shared/redux";
import { HeaderHistoryTools, HistoryToolsSmallScreen } from "@shared/features/DesignTools";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useInlineCropping } from "@internal/feature-inline-cropping";
import { getPanelsForHistoryAction } from "../helpers";
import * as styles from "./HistoryToolbar.module.scss";

export const HistoryToolbar = observer(() => {
    const dispatch = useAppDispatch();
    const designEngine = useDesignEngine();
    const { canRedo, canUndo } = designEngine.historyStore;
    const { isSmall } = useStudioLayout();
    const cropping = useInlineCropping();

    const cropActive = useMemo(() => cropping.isInlineCroppingAvailable && cropping.cropInfo.isCropActive, [cropping]);
    const undoDisabled = useMemo(() => !canUndo || cropActive, [canUndo, cropActive]);
    const redoDisabled = useMemo(() => !canRedo || cropActive, [canRedo, cropActive]);

    const processPanelsForHistoryAction = (panels: PanelState[]) => {
        if (panels.length && !panels.some(panel => panel.id === designEngine.idaStore.activeDesignPanelId)) {
            designEngine.layoutStore.setVisiblePanelIds([panels[0].id]);
            designEngine.idaStore.setActiveDesignPanelId(panels[0].id);
            dispatch(resetAlerts());
            dispatch(autoClosableAlert());
            dispatch(
                setAlerts({
                    alerts: [
                        {
                            key: "studio.components.Toast.activePanelChangedByHistoryToast",
                            variables: {
                                panel: panels[0].panelProperties.name
                            },
                            skin: "warning"
                        }
                    ]
                })
            );
        } else if (panels.length > 1) {
            dispatch(resetAlerts());
            dispatch(autoClosableAlert());
            dispatch(
                setAlerts({
                    alerts: [
                        {
                            key: "studio.components.Toast.panelsModifiedByHistoryToast",
                            variables: {
                                panels: panels.map(panel => panel.panelProperties.name).join(", ")
                            },
                            skin: "warning"
                        }
                    ]
                })
            );
        }
    };

    const undo = () => {
        const panels = getPanelsForHistoryAction(designEngine.historyStore, designEngine.cimDocStore, "undo");

        designEngine.historyStore.undo();

        processPanelsForHistoryAction(panels);
    };

    const redo = () => {
        const panels = getPanelsForHistoryAction(designEngine.historyStore, designEngine.cimDocStore, "redo");

        designEngine.historyStore.redo();

        processPanelsForHistoryAction(panels);
    };

    // the nested ternary will be removed after the AB test for history tools in the header
    // eslint-disable-next-line no-nested-ternary
    return isSmall ? (
        <HistoryToolsSmallScreen
            undo={undo}
            redo={redo}
            canRedo={!redoDisabled}
            canUndo={!undoDisabled}
            transitionIn={!undoDisabled || !redoDisabled}
            marginLeft={"3"}
            marginTop={"4"}
            className={styles.smallScreenHistoryToolbar}
        />
    ) : (
        <HeaderHistoryTools undo={undo} redo={redo} canRedo={!redoDisabled} canUndo={!undoDisabled} />
    );
});
